<template>
  <v-dialog v-model="showDialog" max-width="400">
    <v-card flat class="w-100">
      <template v-if="componentLoading || paymentLoading">
        <v-card-text style="min-height: 120px; height: 100%" class="d-flex justify-content-center align-items-center pa-0">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-card-text>
      </template>
      <template v-else>
        <v-card-title>
          Оплата
        </v-card-title>
        <v-card-text>
          
          <v-row class="mx-0" v-if="displayedMessage">
            <v-col cols="12">
              {{ displayedMessage }}
            </v-col>
          </v-row>
          
          <v-row class="mx-0">
            <v-form ref="payment-discount-form">
              <v-expansion-panels
                :disabled="promocodeLoading || disableOptions"
                accordion
                flat
                tile
                :style="{'--exp-color': vuetifyColors.grey.lighten1}"
                v-model="panelModel"
              >
          
                <v-expansion-panel class="custom-expansion-panel" :key="0">
            
                  <v-expansion-panel-header>
                    <div><v-icon small left>fas fa-percentage</v-icon>Использовать промокод</div>
                  </v-expansion-panel-header>
            
                  <v-expansion-panel-content>
                    <v-text-field
                      id="payment-promocode"
                      placeholder="Введите промокод"
                      filled
                      dense
                      autocomplete="off"
                      v-model="currentPromocode"
                      :loading="promocodeLoading"
                    >
                      <template v-slot:append>
                        <div class="mt-1" @click="applyPromocode()">
                          <v-icon small color="success" style="cursor: pointer" title="Применить промокод">fas fa-check</v-icon>
                        </div>
                      </template>
                    </v-text-field>
                  </v-expansion-panel-content>
          
                </v-expansion-panel>
          
                <v-expansion-panel class="custom-expansion-panel" :key="1">
            
                  <v-expansion-panel-header>
                    <div><v-icon small left>fas fa-ruble-sign</v-icon>Использовать бонусные рубли</div>
                  </v-expansion-panel-header>
            
                  <v-expansion-panel-content>
                    <div class="text-caption d-flex flex-wrap justify-content-between">
                      <span>Баланс бонусных рублей:&nbsp;</span>
                      <template v-if="bonusBalanceLoading">
                        <div class="flex-grow-1 d-flex align-items-center justify-content-center px-4">
                          <v-progress-linear indeterminate rounded color="primary"></v-progress-linear>
                        </div>
                      </template>
                      <template v-else>
                        <span class="font-weight-bold">
                            {{ bonusBalance }}
                        <span style="font-size: 90%">&nbsp;₽</span></span>
                      </template>
                    </div>
                    <div class="text-caption d-flex flex-wrap justify-content-between mb-2">
                      <span>Доступно для списания:&nbsp;</span>
                      <template v-if="bonusBalanceLoading">
                        <div class="flex-grow-1 d-flex align-items-center justify-content-center px-4">
                          <v-progress-linear indeterminate rounded color="primary"></v-progress-linear>
                        </div>
                      </template>
                      <template v-else>
                        <span class="font-weight-bold">
                            {{ maxBonusThatCanBeSpend }}
                        <span style="font-size: 90%">&nbsp;₽</span></span>
                      </template>
                    </div>
                    <v-text-field
                      id="payment-bonus-amount"
                      placeholder="Количество бонусных рублей"
                      filled
                      dense
                      autocomplete="off"
                      v-model="bonusRoublesAmount"
                      :rules="[
                        value => value <= maxBonusThatCanBeSpend ? true : `Вы не можете использовать больше ${maxBonusThatCanBeSpend} ₽`,
                        value => (value !== '' && value != null && value > 0) ? true : `Вы не можете списать это количество`,
                        value => (value == Math.trunc(value)) ? true : `Можно списывать только целое количество бонусных рублей`
                      ]"
                      type="number"
                    >
                      <template v-slot:append>
                        <div class="mt-1" @click="applyBonusRoubles()">
                          <v-icon small color="success" style="cursor: pointer" title="Применить бонусы">fas fa-check</v-icon>
                        </div>
                      </template>
                    </v-text-field>
                  </v-expansion-panel-content>
          
                </v-expansion-panel>
        
              </v-expansion-panels>
            </v-form>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-content-end">
          <v-btn :disabled="promocodeLoading || paymentLoading" text color="grey darken-1" @click="showDialog = false">Закрыть</v-btn>
          <v-btn :disabled="promocodeLoading || paymentLoading" text color="primary" @click="goToPayment()">Перейти к оплате</v-btn>
        </v-card-actions>
      </template>

      <v-snackbar
        v-model="showSnackbar"
        text
        outlined
        :color="snackbarColor"
        flat
        timeout="20000"
      >
				<v-icon small :color="`${snackbarColor} darken-1`" left>fas fa-percentage</v-icon>
				{{ snackbarText }}
			
				<template v-slot:action="{ attrs }">
					<v-btn
						text
						small
						dense
						v-bind="attrs"
						:color="`${snackbarColor} darken-1`"
						@click="showSnackbar = false"
					>
						<v-icon x-small>fas fa-times</v-icon>
					</v-btn>
				</template>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>

import colors from 'vuetify/lib/util/colors';
import { mapGetters } from "vuex";

export default {
  
  name: "PaymentDialog",
  
  computed: {
    ...mapGetters(['STATIC', 'getUser']),
    
    // Получить цену услуг в талоне
    getTotalServicesAmout() {
      let services = [];
      if (this.appointment && this.appointment.services) {
        services = this.appointment.services ?? [];
      }
      return services.reduce( (prev, val) => prev + val.serviceTotalAmount, 0);
    },
    
    // Максимальное количество бонусов которое можно потратить
    maxBonusThatCanBeSpend() {
      const servicesPrice = this.getTotalServicesAmout / 100;
      return Math.min( Math.trunc( servicesPrice * (this.STATIC.maxBonusRoublesPercent / 100) ), this.bonusBalance );
    }
  },
  
  data: () => ({
    showDialog: false,
    vuetifyColors: colors,
    currentPromocode: null,
    bonusRoublesAmount: null,
    bonusBalance: 0,
    bonusBalanceLoading: false,
    panelModel: null,
    showDiscount: false,
    promocodeLoading: false,
    displayedMessage: null,
    disableOptions: false,
    componentLoading: false,
    bonusesUsed: false,
    showSnackbar: false,
    snackbarText: '',
    snackbarColor: '',
    paymentLoading: false
  }),
  
  methods: {
    // Получить бонусный баланс
    getBonusBalance() {
      this.bonusBalanceLoading = true;
      this.$http.post(window.config.apiUrl + '/customer-bonuses/get-balance')
        .then( ({data}) => {
          if (data.status == 'success') {
            this.bonusBalance = data.balance;
            this.bonusBalanceLoading = false;
          }
        } )
        .catch( error => console.log(error) );
    },
  
    // Применить скидку бонусными рублями
    applyBonusRoubles() {
      const form = this.$refs['payment-discount-form'];
      if (!form.validate()) {
        return;
      }
      this.panelModel = null;
      this.promocodeLoading = true;
      this.$http.post(window.config.apiUrl + '/payments/use-bonuses', {
        idCustomer: this.getUser.id,
        idPayment: this.appointment.idPayment,
        amount: this.bonusRoublesAmount
      })
        .then( ({data}) => {
          if (data.status == 'success') {
            this.$emit('updateAppointmentsData');
            this.snackbarText = 'Бонусные рубли успешно применены';
            this.snackbarColor = 'success';
            this.displayedMessage = `Бонусные рубли применены`;
            this.bonusesUsed = true;
            return;
          }
          this.snackbarText = data.errorText ?? data.error ?? 'Возникла ошибка во время применения промокода';
          this.snackbarColor = 'error';
        } )
        .catch( error => {
          console.log(error);
          this.snackbarText = 'Возникла ошибка во время применения промокода';
          this.snackbarColor = 'error';
        } )
        .finally( () => {
          this.promocodeLoading = false;
          this.disableOptions = true;
          this.showSnackbar = true;
        } );
    },
    
    // Применить промокод
    applyPromocode() {
      this.promocodeLoading = true;
      
      this.$http.post(window.config.apiUrl + '/payments/apply-promocode', {
        idPayment: this.appointment.idPayment,
        promocode: this.currentPromocode
      })
        .then( ({data}) => {
          if (data.status == 'success') {
            this.$emit('updateAppointmentsData');
            this.snackbarText = 'Промокод успешно применен';
            this.snackbarColor = 'success';
            this.displayedMessage = `Промокод успешно применен. ${data.discountInfo}`;
            return;
          }
          this.snackbarText = data.errorText ?? data.error ?? 'Возникла ошибка во время применения промокода';
          this.snackbarColor = 'error';
        } )
        .catch( error => {
          console.log(error);
          this.snackbarColor = 'error';
          this.snackbarText = 'Возникла ошибка во время применения промокода';
        } )
        .finally( () => {
          this.promocodeLoading = false;
          this.panelModel = null;
          this.showSnackbar = true;
        } );
      
    },
  
    // Переход к оплате (эквайринг)
    goToPayment() {
      this.paymentLoading = true;
      // Подготавливаем адрес возврата после оплаты
      let paymentStatusUrl = '';
      if (window.indexPlatform == 'ios'){
        paymentStatusUrl = window.config.url + '/cordova-ios.html'
      }
      if (window.indexPlatform == 'android'){
        paymentStatusUrl = window.config.url + '/cordova-android.html'
      }
      if (window.indexPlatform == 'browser'){
        paymentStatusUrl = window.config.url + '/index.html'
      }
      // Создаем заказ
      this.$http.post(window.config.apiUrl + '/payments/create-payment-order', {
        idPayment: this.appointment.idPayment,
        paymentStatusUrl
      })
        .then((response) => {
          this.paymentLoading = false;
          // Если нет ошибок
          if (response.data.result.status == 'success'){
            console.log(response.data.result.acquiring);
            // Если форма оплаты получена
            if (response.data.result.acquiring.formUrl){
              // Перенаправляем на форму оплаты
              window.location = response.data.result.acquiring.formUrl;
            } else {
              this.paymentErrorText = 'Не удалось перейти на страницу оплаты';
              this.errorPaymentDialog = true;
            }
          } else {
            this.paymentErrorText = response.data.result.error;
            this.errorPaymentDialog = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.paymentErrorText = '';
          this.errorPaymentDialog = true;
          this.paymentLoading = false;
        });
    }
  
  },
  
  model: {
    prop: 'show',
    event: 'changeVisibility'
  },
  
  props: {
    show: {
      type: Boolean,
      default: false
    },
    
    appointment: {
      type: null,
      default: null
    }
  },
  
  watch: {
    
    showDialog(newValue) {
      if (newValue == true) {
        if (this.bonusesUsed) {
          this.goToPayment();
        }
        this.getBonusBalance();
  
        this.componentLoading = true;
  
        this.$http.post(window.config.apiUrl + '/payments/is-bonus-used', {
          idPayment: this.appointment.idPayment,
          idCustomer: this.getUser.id
        })
          .then( ({data}) => {
            if (data.status == 'success') {
              if (data.used == true) {
                this.goToPayment();
              } else {
                this.componentLoading = false;
              }
            }
          })
          .catch( error => console.log(error) );
      }
      
      this.$emit('changeVisibility', newValue);
    },
    
    show(newValue) {
      this.showDialog = newValue;
    }
  }
  
}

</script>

<style scoped>

</style>