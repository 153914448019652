export default { 
    changeTemplateItemValues(state, data){
      state.templateValues[data.index].value = data.itemValue
    },
    changeTemplateValues(state, data){
      console.log(data);
      state.templateValues = data.map(item => {
        return {
          id: item.id,
          type: item.itemType,
          value: null,
        } 
      })
    },
}

