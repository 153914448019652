<template>
  <div>
    <div class="header-mobile">
      <v-container>
        <div class="top-toolbar">
            <div class="navigate-btn" >
                <v-btn icon @click="toBack">
                    <v-icon>fas fa-angle-left</v-icon>
                </v-btn>
            </div>
            <div class="page-title">
                {{title}}
            </div>
            <div class="second-btn">
            </div>
        </div>
      </v-container>
    </div>
    <div>
      <v-container>
        <div v-html="STATIC.appContacts"></div>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Contact',
  data: function() {
    return {
      title: 'Контакты',
    }
  },
  methods: {
    toBack() {
      this.$router.push('/')
    }
  },
  computed: {
    ...mapGetters([
      'STATIC'
    ])
  },
}
</script>

<style scoped>
a {
  text-decoration: none;
  transition: 0.5s;
  color: #424242;
}

a:hover {
  text-decoration: none;
  color: #318eb1;
  transition: 0.5s;
}

h1,
h4,
p,
a {
  text-align: center;
}

.content-heading {
  font-size: 30px;
}

.site-contact {
  height: 500px;
}
.contact-wrap {
  display: flex;
  justify-content: space-around;
}
.contact-item-1 {
  font-weight: 700;
}
.contact-item-2 {
  font-weight: 700;
}
.contact-item-icon {
  color: #424242;
  font-size: 40px;
  text-align: center;
  display: block;
  padding: 0 0 20px 0;
  transition: 0.5s;
}
.contact-item-1 > i {
  color: #3198bf;
  transition: 0.5s;
}
.contact-item-2 > i {
  color: #8ac651;
  transition: 0.5s;
}
.contact-item-phone-namber {
  transition: 0.5s;
}
.contact-item-1 > .contact-item-phone-namber {
  color: #4a8e0a;
  transition: 0.5s;
}
.contact-item-2 > .contact-item-phone-namber {
  color: #318eb1;
  transition: 0.5s;
}
.contact-item-director {
  margin: 25px 0 10px;
}
.contact-hours-working {
  text-decoration: underline;
}

@media screen and  (max-width: 1200px){

}

</style>
