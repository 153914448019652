export default {
    appointmentToken(state){
        return state.createAppointmentNew.appointmentToken
    },
    date(state){
        return state.createAppointmentNew.date
    },
    time(state){
        return state.createAppointmentNew.time
    },
    address(state){
        return state.createAppointmentNew.address
    },
    adult(state){
        return state.createAppointmentNew.adult
    },
    currentProgress(state){
        return state.createAppointmentNew.currentProgress
    },
    width(state){
        return state.createAppointmentNew.width
    },
    currentDate(state){
        return state.createAppointmentNew.date
    },
    users(state){
        return Object.values(state.createAppointmentNew.users).sort((a,b) => {
            if (a.surname < b.surname) return -1
        })
    },
    activeUsers(state){
        const activeUsers = {}
        for (let key in state.createAppointmentNew.users) {
        if (state.createAppointmentNew.users[key].freeIntervals) activeUsers[key] = state.createAppointmentNew.users[key]
        }

        return Object.values(activeUsers).sort((a,b) => {
            if (a.surname < b.surname) return -1
        })
    },
    userId(state){
        return state.createAppointmentNew.userId
    },
    idSpecial(state){
        return state.createAppointmentNew.idSpecial
    },
    min(state){
        return state.createAppointmentNew.width < 700
    },
    age(state){
        return state.createAppointmentNew.age
    },
    service(state){
        return state.createAppointmentNew.service
    },
    q(state){
        return state.createAppointmentNew.q
    },
    serviceActive(state){
        return state.createAppointmentNew.service ? 
        {
            nameService: state.createAppointmentNew.service.name,
            priceService: state.createAppointmentNew.service.price,
            id: state.createAppointmentNew.service.id,
            requirePrepaymentInApp: state.createAppointmentNew.service.requirePrepaymentInApp,
        } :
        null
    }
}