<template>
  <div class="chat-control" v-if="this.couponService && !this.couponService.completed && this.couponService.serviceAvailableStatus">
    <div class="chat-control-section">
			<div class="attach-control-section">
				<v-btn icon color="grey darken" @click="addAttach" title="Прикрепить вложение">
          <v-icon small>fas fa-paperclip</v-icon>
        </v-btn>
			</div>
			<div class="input-message-section">
				<input type="text" v-model="message" @keyup.enter="sendMessage" @keyup="inputKeyUp" placeholder="Введите сообщение">
			</div>
			<div class="send-btn-section">
				<v-btn icon color="grey darken" @click="sendMessage" :disabled="loadAttach || (!message.trim().length && !attachFiles.length) " title="Отправить сообщение">
          <v-icon small>fas fa-paper-plane</v-icon>
        </v-btn>
			</div>
    </div>
    <div class="attach-section" v-show="attachFiles.length">
			<form id="form_attach" class="form-attach">
				<input type="file" id="input_attach_file" multiple accept=".jpg, .jpeg, .png, .xlsx, .xls, .doc, .docx, .pdf" @change="attachChangeInput">
			</form>
			<div class="files-section">
				<div class="attach-file" v-for="attachFile in attachFiles" :key="attachFile.id" :class="{'can-deleted' : attachFile.load}" @touchstart="attachTouchStart" @touchend="attachTouchEnd(attachFile.guid)">
					<div class="delete-attach" @click="deleteAttach(attachFile.guid)" v-if="attachFile.guid" title="Удалить вложение">
						<i class="far fa-times-circle"></i>
					</div>
					<div v-if="attachFile.load" v-show="attachFile.type == 'png' || attachFile.type == 'jpg' || attachFile.type == 'jpeg'" class="attach-preview-image">
						<v-img cover aspect-ratio="1" v-if="attachFile.load" :src="getFileMessageUrl(attachFile.guid)" class="rounded-sm"></v-img>
					</div>
					<div v-if="!attachFile.load" class="d-flex h-100">
						<v-progress-circular :size="40" :width="6" indeterminate color="#d8d8d8" class="m-auto"></v-progress-circular>
					</div>
					<div v-if="attachFile.load" v-show="attachFile.type != 'png' && attachFile.type != 'jpg' && attachFile.type != 'jpeg'" class="attach-preview-file">
						<i class="far fa-file-alt"></i>
						<div class="file-info" v-if="attachFile.load">
							{{attachFile.name}}.{{attachFile.type}}
						</div>
						<div class="file-info" v-else>
							{{attachFile.name}}
						</div>
					</div>
				</div>
				<div v-if="attachFiles.length" class="plus-attach-btn" title="Добавить вложение" @click="addAttach">   
						<i class="fas fa-plus"></i>
				</div>
			</div>
    </div>
		<v-dialog v-model="uploadFileErrorDialog" max-width="400">
			<v-card>
				<v-card-title>Загрузка файлов</v-card-title>
				<v-card-text>
					<p>Не удалось загрузить файл. {{this.uploadFileErrorText}}</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn rounded text color="primary" dark @click="uploadFileErrorDialog = false">Закрыть</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="removeAttachDialog" max-width="400">
			<v-card>
				<v-card-title>Удаление вложения</v-card-title>
				<v-card-text>
					<p>Вы действительно хотите удалить вложение?</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn rounded text color="primary" dark @click="removeAttachDialog = false">Нет</v-btn>
					<v-btn rounded text color="primary" dark @click="deleteAttach(removeAttachGuid)">Да</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
  </div>
</template>

<script>
import store from '@/store'
import eventBus from '../../../eventBus';
import socket from '@/services/socket.js'
import moment from 'moment';
import {mapGetters} from 'vuex';

var keyupMessagesTimeOut;

export default {
  data() {
    return {
      message: '',
      attachFiles: [],
      loadAttach: false,
      id: this.$store.getters.getUser.id,
      uploadFileErrorDialog: false,
      removeAttachDialog: false,
      uploadFileErrorText: '',
      attachTouchTime: 0,
      removeAttachGuid: null
    }
  },
  created: function(){
    // Событие отправки сообщения
    eventBus.$on('clearMessageInput', () => {
      this.attachFiles = [];
      this.message = '';
    })
  },
  computed: {
    ...mapGetters([
      'couponService',
      'currentCouponService',
      'couponServices'
    ]),
  },
  methods: {
        // Отправка сообщения
        getFileMessageUrl(guid){
            return `${window.config.apiUrl}/chat/get-file-message?guid=${guid}&size=small&id=${this.id}&type=customer`
        },
        attachTouchStart(){
            this.attachTouchTime = +new Date();
        },
        attachTouchEnd(removeAttachGuid){
            this.removeAttachGuid = removeAttachGuid;
            let currTime = +new Date();
            if ((currTime - this.attachTouchTime ) > 600){
                this.removeAttachDialog = true
            }
            event.preventDefault()
            return false;
        },
        inputKeyUp(){
            clearTimeout(keyupMessagesTimeOut);
            keyupMessagesTimeOut = setTimeout(function () {
                eventBus.$emit('readMessages');
            }, 2000)
        },
        sendMessage(){
            if (this.message.trim() || this.attachFiles.length){
                // Воспроизводим звук отправки
                let audio = new Audio(require('@/assets/empty.mp3')); 
                audio.play();

                // Подготавливаем вложения
                let messageAttachFiles = [];
                for (let key in this.attachFiles){
                    messageAttachFiles.push({
                        'guid': this.attachFiles[key].guid,
                        'type': this.attachFiles[key].type,
                        'name': this.attachFiles[key].name
                    });
                }
                let guid = (Math.floor(Math.random() * Math.floor(900))) + Date.now() + this.currentCouponService;

                // Подготавливаем файлы для отправки в сокет
                let socketAttachFiles = [];
                for (let key in messageAttachFiles){
                    socketAttachFiles.push(messageAttachFiles[key].guid);
                }

                // Отправляем сообщение
                let request = {
                    event: 'sendMessage', 
                    fromUser: false,
                    message: this.message, 
                    files: socketAttachFiles,
                    couponService: this.currentCouponService,
                    guid: guid, 
                    token: this.$store.getters.token,
                    type: 'customer',
                    id: this.$store.getters.getUser.id
                };
                socket.socketSend(JSON.stringify(request));

                // Добавляем сообщение в чат
                let couponService = this.couponServices.filter(couponService => couponService.id == this.currentCouponService)[0];
                couponService.messages.push({
                    id: Date.now(), 
                    text: this.message,
                    guid: guid,
                    date: moment().format('YYYY-MM-DD HH:mm'),
                    files: messageAttachFiles,
                    fromUser: false,
                    send: false,
                    read: false,
                });
                    
                this.message = '';
                // Очищаем вложения
                document.querySelector('#input_attach_file').value = '';
                this.attachFiles = [];
            
                let chatMessages = document.querySelector('.chat-messages')
                setTimeout(() => {
                    if (chatMessages){
                        eventBus.$emit('scrollChat');
                    }
                }, 300);
        
            }
        },
        // Добавление вложения
        addAttach(){
            document.querySelector('#input_attach_file').click();
            eventBus.$emit('readMessages');
        },
        // Удаление вложения
        deleteAttach(guid){
            this.attachFiles = this.attachFiles.filter(file => file.guid != guid);
            
            fetch(window.config.apiUrl + '/chat/delete-file-message', {
                method: 'POST',
                body: new URLSearchParams({
                    guid: guid,
                    token: this.$store.getters.token,
                    id: this.$store.getters.getUser.id,
                    type: 'customer'
                })
            }).then(response => response.json())
            .then(() => {
     
            });
            this.removeAttachDialog = false;
        },
        attachChangeInput(){
            let fileInput = document.querySelector('#input_attach_file');
            var files = fileInput.files;
            let guid = '';
            if (files){
                let input = document.querySelector('#input_attach_file');
                let data = new FormData();
                for (let i = 0; i < fileInput.files.length; i++){
                    data.append('ChatMessagesFiles[messageFiles][]', input.files[i]);
                    guid = (Math.floor(Math.random() * Math.floor(900))) + Date.now();
                    files[i].guid = guid;
                    this.attachFiles.push({
                        id: guid,
                        name: files[i].name,
                        size: parseInt(files[i].size / 1024) + ' КБ',
                        type: null,
                        load: false
                    })
                }
                this.loadAttach = true;
                data.append('ChatMessagesFiles[token]', this.$store.getters.token);
                data.append('ChatMessagesFiles[couponService]', this.currentCouponService); 
                data.append('ChatMessagesFiles[id]', this.$store.getters.getUser.id);
                data.append('ChatMessagesFiles[type]', 'customer');   
                // Загрузка вложений
                fetch(
                    window.config.apiUrl + '/chat/upload-message-files', {
                    method: 'POST',
                    body: data
                }).then(response => response.json())
                .then((response) => {
                    if (response.status == 'success'){
                        this.loadAttach = false;
                        this.attachFiles = this.attachFiles.filter(file => file.load == true)
                        for (let key in response.files){
                            this.attachFiles.push({
                                id: (Math.floor(Math.random() * Math.floor(900))) + Date.now(),
                                name: response.files[key].info.name,
                                size: parseInt(response.files[key].info.size / 1024) + ' КБ',
                                type: response.files[key].info.type,
                                guid: response.files[key].info.guid,
                                load: true
                            });
                        }
                    } else {
                        if (response.errors){
                            for (let key in response.errors){
                                this.uploadFileErrorText = response.errors[key][0];
                                this.uploadFileErrorDialog = true;
                                this.attachFiles = []
                                console.log('error', 'Ошибка',response.errors[key][0])
                            }
                        } else {
                            console.log('error', 'Ошибка', 'Не удалось добавить вложение');
                        }
                    }
                    document.querySelector('#input_attach_file').value = '';
                });  
            }
        }
    }
}
</script>

<style scoped>

.chat-control {
  padding: 13px 0 0 0;
  border-top: 1px solid #efeded;
}

.chat-control-section {
  display: flex;
}

.input-message-section {
	width: 100%;
	padding: 0 2px;
}

.input-message-section input {
	border: none;
	padding: 7px 15px;
	border-radius: 50px;
	width: 100%;
	background: #f7f7f8;
}

.input-message-section input:focus{
  outline: none;
}

.form-attach{
  display: none;
}

.attach-section{
  padding-top: 10px;
}

.attach-file{
	padding: 10px;
	margin-right: 10px;
	background: #f7f7f8;
	border-radius: 10px;
	width: 100px;
	height: 100px;
	user-select: none;
	transition: all .25s ease-in-out;
}

.files-section{
  display: flex;
}

.file-info{
	font-size: 11px;
	color: #7e7e7e;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.attach-file img{
	width: 80px;
	object-fit: cover;
	object-position: center;
	border-radius: 4px;
	height: 80px;
}


.attach-preview-file{
	font-size: 35px;
	text-align: center;
	padding: 5px;
	color: #9ba6b2;
}

.delete-attach{
  display: none;
}

.attach-file.can-deleted:hover .delete-attach{
	background: #00000040;
	position: absolute;
	width: 80px;
	height: 80px;
	border-radius: 5px;
	display: flex;
	font-size: 35px;
	color: #ffffffbf;
	cursor: pointer;
	z-index: 10;
}

.delete-attach i{
  margin: auto;
}

.plus-attach-btn{
	padding: 10px;
	margin-right: 10px;
	background: #f7f7f8;
	border-radius: 10px;
	width: 100px;
	height: 100px;
	display: flex;
	font-size: 35px;
	color: #9ba6b2;
	cursor: pointer;
	transition: .2s color ease-in-out;
}

.plus-attach-btn i {
  margin: auto;
}

.plus-attach-btn:hover{
  color: #7f8b98;
}


.attach-file.can-deleted:active{
  background: #cfcfcf;
}


@media screen and  (max-width: 1200px){

	.chat-control {
		padding: 13px 10px;
		position: fixed;
		bottom: 55px;
		background: white;
		width: 100%;
		left: 0;
	}

	.attach-file .delete-attach{
		display: none !important;
	}

}

</style>