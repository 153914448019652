// Мутации теле-медицины
import moment from 'moment';

export default {
  sendChatNotify(state, {notify, idCouponService}){
    state.chat.couponServices.forEach((element, index) => {
      if (element.id == idCouponService){
        state.chat.couponServices[index].messages.push({
          id: Date.now(), 
          text: notify,
          date: moment().format('YYYY-MM-DD HH:mm'),
          fromUser: false,
          send: false,
          read: false,
          isNotify: true
        });
      }
    });
  },
  // Изменение статуса звонка
  changeCallStatus(state, callStatus){
    state.chat.callStatus = callStatus;
  },
  // Установить услуги чата
  setCouponServices(state, couponServices){
    state.chat.couponServices = couponServices;
  },
  // Установить текущую активную услугу чата
  setCurrentCouponService(state, idCouponService){
    state.chat.currentCouponService = idCouponService;
  },
  // Установить режим микрофона
  setMutedMode(state, mode){
    state.chat.mutedMode = mode;
  },
  // Установить статус доступности услуги чата
  setChatCouponServiceAvailable(state, {idCouponService, status}){
    state.chat.couponServices.forEach((element, index) => {
      if (element.id == idCouponService){
        state.chat.couponServices[index].serviceAvailableStatus = status;
      }
    });
  }
}