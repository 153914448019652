<template>
  <v-container>
    <v-row>
        <v-col
          md="10"
          offset-md="1"
          sm="12"
          xs="12"
        >
          <v-skeleton-loader
            type="article, actions"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="article, actions"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="article, actions"
          ></v-skeleton-loader>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    attrs: {
      class: 'mb-6',
      boilerplate: true,
      elevation: 2,
    },
  }),
}
</script>

<style>

</style>