<template>
  <div>
    <!-- <v-toolbar
      flat
      color="primary"
      dark
    >
      <v-toolbar-title>User Profile</v-toolbar-title>
    </v-toolbar> -->
    <v-tabs vertical>
      <v-tab 
        v-for="(arr, label) in sortServices" 
        :key="label" 
        class="tab-label"
        @click="labelClickHandler"
      >
          {{label}}
      </v-tab>

      <v-tab-item 
        v-for="(arr, label) in sortServices" 
        :key="label" 
        transition="none" 
        reverse-transition="none"
      >
        <v-card flat>
          <v-card-text>
            <!-- <p 
              class="service-name"
              v-for="(service, index) in arr" 
              :key="index" 
              v-html="formatResult(service.name)"
              @click="serviceClickHandler(service)"
            > -->
            <div 
              class="service-name"
              v-for="(service, index) in arr" 
              :key="index"
              @click="serviceClickHandler(service)"
            >
              {{service.name}}
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      
    </v-tabs>
  </div>
</template>

<script>
import router from '../../router'
export default {
  props: {
    sortServices: Object,
    q: String,
  },
  methods: {
    // функция выделения совпадений по вводимому в инпут тексту
    formatResult(val){
      if (!val) return
      let q = this.q.toLowerCase()
      let arr = val.split(q)
      if (arr.length < 2) {
        q = q[0].toUpperCase() + q.slice(1)
        arr = val.split(q)
      }
      let res = ''
      for (let i=0; i<arr.length; i++){
        res += arr[i]
        if (i === arr.length-1) continue
        res += `<b>${q}</b>`
      }
      return res
    },
    // обработка выбора специальности
    serviceClickHandler(service){
      this.services = ''
      this.$store.commit('setService', service)
      localStorage.service = JSON.stringify(service)
      this.$store.commit('setDate', '')
      router.push('/users-by-service')
    },
    labelClickHandler(){
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style scoped>
  .tab-label{
    font-size: 1.5rem;
    padding: 0 !important;
  }
  .v-tab{
    min-width: 50px;
  }
  .v-card__text{
    word-wrap: break-word;
  }
  .service-name{
    max-width: 70vw;
    margin-bottom: 15px;
  }
  .service-name:hover{
    cursor: pointer;
    color: var(--primary-color);
  }
  .service-name::first-letter{
    font-weight: bold;
    color: var(--primary-color);
  }
</style>
