<template>
  <div>
    <ProgressBar :progress="3"/>
    <UserCard :user="user" :linkDisabled="true" :showIntervals="false"/>
    <CalendarLine :activeDates="activeDates" :getAllowedDates="getAllowedDates" v-if="activeDates"/>
    <v-container>
      <v-row>
        <v-col
          md="10"
          offset-md="1"
          sm="12"
          xs="12"
        >
          <TimePicker :freeIntervals="user.freeIntervals" @time-selected="timeSelectedHandler(user.id)" v-if="showIntervals"/>
          <div class="notify" v-else-if="activeDates">
            <i class="fas fa-exclamation-circle"></i><br>
            Нет свободного времени {{formatedDate}}
          </div>
          <div class="notify" v-else>
            <i class="fas fa-exclamation-circle"></i><br>
            Для записи к этому специалисту обратитесь в регистратуру
            <div>{{STATIC.phoneWork}}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import ProgressBar from '../../components/appointment/ProgressBar.vue'
import CalendarLine from '../../components/appointment/CalendarLine.vue'
import UserCard from '../../components/appointment/UserCard.vue'
import TimePicker from '../../components/appointment/TimePicker.vue'

import moment from 'moment'
import { mapGetters } from 'vuex'
import router from '../../router'

export default {
  created(){
    // переадресация, если пользователь не выбран
    if (!this.userId) router.push('/users')
    // установка сегодняшней даты в качестве текущей
    // const formatDate = moment(new Date).format(moment.HTML5_FMT.DATE)
    // this.$store.commit('setDate', formatDate)
    // localStorage.date = formatDate
    // получение данных о пользователе
    this.getData()
  },
 data(){
   return {
     user: {},
     activeDates: {},
   }
 },
 components: {
   ProgressBar,
   CalendarLine,
   UserCard,
   TimePicker,
 },
 methods: {
   // получение данных о пользователе
   async getData(){
     const dateTo = moment().add(1, 'M').format(moment.HTML5_FMT.DATE)
     const showFreeIntervals = true
     const showUserServices = false
      this.user = await this.$store.dispatch('getUser', {
        dateTo,
        showFreeIntervals,
        showUserServices
      })
      this.activeDates = this.user.freeIntervals
    },
    // обработка выбора времени приема в тайм пикере
    timeSelectedHandler(userId){
      this.$store.commit('setUserId', userId)
      // переадресация на подтвержение записи
      router.push('/new-appointment/apply-appointment')
    },
    // функция определения неактивных дат в дейтпикере
    getAllowedDates (val) {
        if (this.activeDatesArr.indexOf(val) !== -1) {
          return true
        } else {
          return false
        }
      },
 },
 computed: {
   ...mapGetters(['date', 'userId', 'STATIC']),
  //  поиск свободного времени в объекте с датами
   showIntervals(){
     const intervals = this.user.freeIntervals ? this.user.freeIntervals : {}
     return (this.date in intervals)
   },
  //  форматирование даты для отображения
   formatedDate(){
     return moment(this.date).locale('ru').format("Do MMM");
   },
   activeDatesArr(){
     const res = []
     for (let key in this.user.freeIntervals){
       res.push(key)
     }
     return res
   }
 }
}
</script>

<style scoped>
  .notify{
    color: rgb(140, 140, 140);
    text-align: center;
  }
</style>