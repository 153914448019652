<template>
  <v-snackbar
    v-model="networkError"
    color="red"
    outlined
    :timeout="-1"
  >
    <div class="snackbar-container">
      <div>
        <i class="fas fa-wifi"></i>
        {{ text }}
      </div>

      <div @click="btnClickHandler">
        <i class="fas fa-times"></i>
      </div>
    </div>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    snackbar: false,
    text: `Интернет соединение недоступно`,
  }),
  computed: mapGetters(['networkError']),
  methods: {
    btnClickHandler(){
      this.$store.commit('toggleErrorSnackbar', false)
      this.$store.state.requestTimer = null
    }
  }
}
</script>

<style scoped>
  .snackbar-container{
    display: flex;
    justify-content: space-between;
  }
</style>