// Хранилище состояний работы с записями

export default {
    createAppointment: {
        selectedSpecial: null,
        selectedUser: null,
        selectedAppointment: null,
        selectedServiceType: null,
        scheduleServices: null
    },
}