export default {
    // Получить телефон при авторизации
    LOGIN_PHONE: state => state.login.phone,
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    url: state => state.url,
    activeUser:  state => state.activeUser || localStorage.getItem('activeUser'),
    token: state => state.token ||  localStorage.getItem('token'),
    getRepresentatives: state => state.user.representatives,
    getUser: state => state.user,
    getActiveUserName: (state) => {
      if (state.user.id){
        if (state.user.id == state.activeUser){
          return state.user.surname + ' ' + state.user.name + ' ' + state.user.middleName;
        } else {
          if (state.user.representatives){
            let representative = state.user.representatives.filter((user) => {
              return user.id == state.activeUser
            });
            if (representative){
              return representative[0].surname + ' ' + representative[0].name + ' ' + representative[0].middleName;
            }
          }
        }
      } else {
        return '';
      }
    },
}