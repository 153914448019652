<template>
  <div>
    <ProgressBar :progress="2"/>
    <ServicesList />
  </div>
</template>

<script>

import ProgressBar from '../../components/appointment/ProgressBar.vue'
import ServicesList from '../../components/appointment/ServicesList.vue'

export default {
  name: "Services",
  data(){
    return {
      // отображение загрузки при получении данных
      loading: false,
    }
  },
  components: {
    ProgressBar,
    ServicesList,
  },
}
</script>

<style scoped>

</style>