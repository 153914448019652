import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0093c1',// '#1E88E5', 
      //  secondary: colors.red.lighten4, // #FFCDD2
      },
    },
  },
});
