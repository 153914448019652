<template>
  <div class="chat-messages-wrap">
    <div v-if="this.couponService" v-show="(this.couponService.onlineVideo && (this.callStatus == 'wait-user' || this.callStatus == 'call'))" class='chat-video'>
      <video poster="@/assets/video-bg.png" class="remote-video" id="remoteVideo" playsinline webkit-playsinline autoplay="true" width="100%" height="100%"></video>
      <video poster="@/assets/video-bg.png" v-if="this.callStatus == 'call'" class="local-video" muted playsinline webkit-playsinline autoplay="true" id="localVideo"  width="100%" height="50%"></video>
      <div class="video-control-wrap" :class="{
        'wait': this.callStatus == 'wait',
        'call': this.callStatus == 'call'
      }">
        <div class="video-info" v-if="this.callStatus != 'call'">
          <img data-v-412d3c2f="" :src="getUserPhoto(this.couponService.idUser)" alt="">
          <h6 v-if="this.callStatus == 'wait'">Ожидание видео-звонка</h6>
          <h5 v-if="this.callStatus == 'wait-user'">Вызов...</h5>
          <h5 v-if="this.callStatus == 'completed'">Услуга завершена</h5>
          <h5 class="my-1">{{this.couponService.userName}}</h5>
          <p>{{this.couponService.serviceName}}</p>
        </div>
        <div class="video-control">
          <div v-if="this.callStatus == 'wait'">
            <div class="video-btn call-start" @click="call">
              <i class="fas fa-phone-alt"></i>
              <span></span>
            </div>
          </div>
          <div v-if="this.callStatus == 'wait-user'">
            <div class="video-btn call-end" @click="rejectCall">
              <i class="fas fa-phone-slash"></i>
              <span>Завершить</span>
            </div>
          </div>
          <div class="active-call" v-if="this.callStatus == 'call'">
            <div class="all-control" v-if="!this.visibledRejectMenu">
              <div class="video-btn mic" :title="this.mutedMode ? 'Включить микрофон' : 'Выключить микрофон'" @click="toggleMuted">
                <i class="fas fa-microphone" v-if="!this.mutedMode"></i>
                <i class="fas fa-microphone-slash" v-if="this.mutedMode"></i>
              </div>
              <div class="video-btn call-end" @click="rejectCall">
                <i class="fas fa-phone-slash"></i>
                <span></span>
              </div>
            </div>                   
          </div>
        </div>
      </div>
    </div>
    <div class="chat-messages" v-on:touchend="mouseDownMessages" v-if="this.currentCouponService" :class="{'no-select-service' : !this.currentCouponService}" @mousemove="readMessages">
        <div class="messages-wrap">
            <div>
            <transition-group name="slide-fade">
                <ChatMessage v-for="message in activeCouponServiceMessages" :key="message.id" :type="message.type" :message="message"/>
            </transition-group>
            </div>
            <div v-if="this.currentCouponService && !this.activeCouponServiceMessages.length" class="no-messages">Нет сообщений</div>
        </div>
    </div>
      <div v-if="!this.currentCouponService" class="no-select-service">
        <img src="@/assets/chat-service-list.png" alt="">
        <h5>Выберите врача для начала диалога</h5>
        <h6>Для того что-бы начать диалог с врачом выберите его в левом списке оплаченых услуг</h6>
      </div>
    </div>
</template>

<script>
import ChatMessage from './ChatMessage'
import eventBus from '../../../eventBus';
import { mapGetters } from 'vuex';
import store from '@/store'
import socket from '@/services/socket.js'
import moment from 'moment';

var scrollMessagesTimeOut;

export default {
    computed: {
        ...mapGetters([
            'currentCouponService',
            'couponService',
            'couponServices',
            'callStatus',
            'activeCouponServiceMessages',
            'mutedMode'
        ]),
    },
    data () {
        return {
            serviceStatusInterval: null,
            serviceAvailable: false,
            visibledRejectMenu: false,
            pauseMode: false,
            audioMode: false,
        }
    },
  
    updated: function(){
        if (this.couponService && this.couponService.completed ){
            this.$store.dispatch('setCallStatus', 'completed');
        }
        // Прокручиваем вних сообщения
        let chatMessages = document.querySelector('.chat-messages')
        if (chatMessages){
            chatMessages.scrollTop = chatMessages.scrollHeight;
        }
    },
    props: [
        'currentVideoCouponService',
    ],
    methods: {
      
        // Получать аватар врача
        getUserPhoto(idUser){
            return window.config.apiUrl + '/file/get-user-photo?idUser=' + idUser
        },
        // Прочитать сообщения
        readMessages(){
            clearTimeout(scrollMessagesTimeOut);
            scrollMessagesTimeOut = setTimeout(function () {
                console.log('Try read messages: move');
                eventBus.$emit('readMessages');
            }, 2000)
        },
        pause(){
            this.pauseMode = !this.pauseMode;
            if (this.pauseMode){
                document.querySelector('#remoteVideo').pause();
            } else {
                document.querySelector('#remoteVideo').play();
            }
        },
        mouseDownMessages(){
            clearTimeout(scrollMessagesTimeOut);
            scrollMessagesTimeOut = setTimeout(function () {
                console.log('Try read messages: touch');
                eventBus.$emit('readMessages');
            }, 2000)
        },
        toggleVideo(){
            this.audioMode = !this.audioMode;
            if (this.audioMode){
                document.querySelector('#remoteVideo').pause();
            } else {
                document.querySelector('#remoteVideo').play();
            }
        },
        toggleMuted(){
           socket.toggleMuted()
        },
        // Отклонить вызов
        rejectCall(){ 
            eventBus.$emit('rejectCall', this.currentCouponService);
            if (window.device && window.device.platform == 'iOS'){
                 document.querySelector('body.ios-body').classList.remove('call');
            }
        },
        // Начать вызов
        call(){
            eventBus.$emit('call', this.currentCouponService);
            // Если на iOS
            if (window.device && window.device.platform == 'iOS'){
                // Убираем постеры
                document.querySelector('body').classList.add('call');
                document.querySelector('#remote-video').removeAttribute('poster');
                document.querySelector('#local-video').removeAttribute('poster');
            }
        },
        // Услуга завершена
        serviceCompleted(){
            eventBus.$emit('rejectCall', this.currentCouponService, true);
        },
        // Отмена меню отклонения
        serviceCancel(){
            this.visibledRejectMenu = false;
        },
        // Отобразить меню завершения вызова
        showRejectMenu(){
            this.visibledRejectMenu = true;
        }
    },
    components: {
        ChatMessage
    }
}
</script>

<style scoped>

.slide-fade-enter-active {
    transition: all .4s ease;
}


.slide-fade-enter{
    transform: translateY(10px);
    opacity: 0;
}

.chat-messages-wrap{
    display: flex;
    overflow-y: hidden;
    height: 100%;
    background: #fafafa;
    border-radius: 10px;
    position: relative;
}

.show-coupon-service .chat-messages-wrap{
  border-radius: 0px;
}

.chat-messages {
    overflow-y: scroll;
    background: #fff;
    /* background-image: url('/frontend/web/images/chat-bg.png'); */
    background-size: 180px;
    padding: 15px 0;
    height: 100%;
    width: 100%;
   -webkit-overflow-scrolling: touch;
}

.chat-messages.no-select-service{
    background: #fff;
}

.no-messages{
    background: #f7f7f8;
    width: fit-content;
    margin: auto;
    padding: 10px 44px;
    border-radius: 50px;
    color: #7e7e7e;
}

.messages-wrap{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100%;
}

.no-select-service{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto;
    width: 100%;
    padding-bottom: 60px;
}

.no-select-service img{
    width: 150px;
    height: 150px;
    margin: auto;
    margin-bottom: 25px;
}

.no-select-service h5{
    color: #5c5c5c;
}

.no-select-service h6{
    max-width: 374px;
    margin: auto;
    color: #5c5c5c;
    font-weight: 400;
}

.chat-video{
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    padding: 0;
    border-radius: 0;
    -webkit-transform: translateZ(0);
}

.chat-video video{
    object-fit: cover;
    object-position: center;
}

.video-control-wrap{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
}

.video-control-wrap.call{
    justify-content: flex-end;
}

.remote-video{
   background: #262626e8;
}

.local-video{
    position: absolute;
    width: 120px;
    height: 150px;
    left: 30px;
    top: 30px;
    /* background: #292929; */
}

.video-btn{
    background: #ffffff;
    color: #000;
    display: flex;
    font-size: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    opacity: 0.7;
    cursor: pointer;
    transition:  .2s ease-in-out opacity;
    position: relative;
    margin-top: 15px;
}

.video-btn:hover{
    opacity: 1;
}

.video-btn i{
    margin: auto;
}

.video-btn.call-end{
    background: #902121;
    width: 60px;
    height: 60px;
    font-size: 25px;
    color: #fff;
}

.video-btn.call-start{
    background: #2b97bf;
    width: 60px;
    height: 60px;
    font-size: 25px;
    color: #fff;
}

.video-btn.service-complete{
    background: #9ece69;
    width: 80px;
    height: 80px;
    margin-top: -10px;
    font-size: 25px;
    color: #000000;
}

.video-control{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.video-info{
    width: 100%;
    margin-right: 23px;
    text-align: center;
    font-size: 16px;
    color: #d7d7d7;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.video-info img{
    margin-bottom: 19px;
    width: 90px;
    height: 90px;
    opacity: 0.7;
    margin: auto auto 15px auto;
    object-fit: cover;
    border-radius: 50px;
    object-position: top;
}

.video-info h6{
    max-width: 400px;
    text-align: center;
    margin: 0 auto auto auto;
    opacity: .5;
}

.video-btn span{
    position: absolute;
    bottom: -30px;
    font-size: 12px;
    width: 100px;
    text-align: center;
    left: -20px;
    color: #fff;
}

.reject-menu{
    display: flex;
    width: 400px;
    justify-content: space-between;
    margin: auto;
}

.active-call{
    width: 500px;
}

.active-call .all-control{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.video-btn.cancel span{
    width: 100px;
}

@keyframes play {

    0% {
        transform: scale(1);
    }
    15% {
        box-shadow: 0 0 0 5px rgba(43, 151, 191, 0.4);
    }
    25% {
        box-shadow: 0 0 0 10px rgba(43, 151, 191, 0.4), 0 0 0 20px rgba(43, 151, 191, 0.2);
    }
    25% {
        box-shadow: 0 0 0 15px rgba(43, 151, 191, 0.4), 0 0 0 30px rgba(43, 151, 191, 0.2);
    }

}

@media screen and  (max-width: 1200px){

    .chat-messages{
        padding: 10px 0;
    }

    .local-video{
        position: fixed;
        left: 20px;
        top: 40px;
        width: 20vw;
        height: 19vh;
        border-radius: 5px;
        background: transparent;
    }

    .video-with-chat .video-control-wrap{
        padding: 10px 60px;
    }

    .video-info{
        font-size: 14px;
    }

}

</style>