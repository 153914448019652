<template>
  <div>
    <v-container> 
      <v-row v-if="!isLoadedDates">
        <v-col class="p-0">
          <div>
            <v-skeleton-loader class="mx-0 p-0" type="article"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="isLoadedDates">
        <v-col cols="12" v-if="!dates.length">
          <v-alert prominent text>
            На данный момент нет свободного времени для записи.
          </v-alert>
        </v-col>
        <v-col class="py-0" cols=12 v-if="dates.length">
          <v-row>
            <div class="date-section" v-if="currentStep == 'date'">
              <h5 class="pb-3">Выберите дату посещения</h5>
              <v-date-picker 
                flat
                landscape
                :elevation="0" 
                outlined
                shaped 
                color="primary"
                :no-title="$mq === 'sm'"
                v-model="picker"  
                :first-day-of-week="1" 
                full-width
                locale="ru-RU"
                :allowed-dates="getAllowedDates"
              >
              </v-date-picker>
            </div>
            <div class="time-section py-4" v-if="currentStep == 'date' && times().length" >
              <h5 class="pb-3">Выберите время</h5>
              <div v-if="filials.length">
                <div v-for="filial in filials" :key="filial.id">
                  <div class="mb-3" v-if="times(filial.id).length">
                    <div>
                      <h6 class="mb-0">{{filial.name}}</h6>
                      <small>{{filial.address}}</small>
                    </div>
                    <v-btn small rounded color="primary" class="m-1 ml-0" dark v-for="time in times(filial.id)" :key="time.timeFrom" @click="selectTime(time)">
                      {{time.timeFrom}} 
                      <v-icon v-if="time.onlineVideo" x-small right title="Чат и видео-консультация с врачом">fas fa-video</v-icon>
                      <v-icon v-if="time.onlineChat || time.onlineVideo" x-small right title="Чат с врачом">fas fa-comment</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
              <div v-if="!filials.length">
                <div class="mb-3" v-if="times().length">
                  <v-btn small rounded color="primary" class="m-1 ml-0" dark v-for="time in times()" :key="time.timeFrom" @click="selectTime(time)">
                    {{time.timeFrom}}
                  </v-btn>
                </div>
              </div>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-container> 
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {
      currentStep: 'date',
      picker: new Date().toISOString().substr(0, 10),
      filials: [],
      freeAppointments: [],
      dates: [],
      isLoadedDates: false,
      selectedAppointmentFilial: null 
    }
  },
  computed: {
    ...mapGetters([
      'CREATE_APPOINTMENT'
    ]),
    filterServices() {
      return this.services.filter(service => (service.onlineChat && this.selectedServiceType == 'chat') || (service.onlineVideo && this.selectedServiceType == 'video'));
    },
    getSelectedFormatDateTime() {
      return this.$moment(this.selectedAppointment.date +  ' ' + this.selectedAppointment.timeFrom).format("LL, dddd, LT");
    },
  },
  methods: {
    ...mapActions([
      'SELECT_APPOINTMENT'
    ]),
    times(idFilial = null) {
      let times = [];
      for (let schedule in this.freeAppointments) {
        if (this.freeAppointments[schedule].date == this.picker){
          if (idFilial){
            if (idFilial == this.freeAppointments[schedule].idFilial){
              times.push(this.freeAppointments[schedule]);    
            }
          } else {
            times.push(this.freeAppointments[schedule]);    
          }
        }
      }
      return times;
    },
    getAllowedDates (val) {
      if (this.dates.indexOf(val) !== -1) {
        return true
      } else {
        return false
      }
    },
    getFilials(){
      this.$http.get(window.config.apiUrl + '/filials',)
        .then((response) => {
          if (response.data){
            this.filials = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFilialName(){
      let filial = this.filials.filter(filial => filial.id == this.selectedAppointmentFilial);
      return filial.length ? filial[0].name : '';
    },
    getFilialAddress(){
      let filial = this.filials.filter(filial => filial.id == this.selectedAppointmentFilial);
      return filial.length ? filial[0].address : '';
    },
    selectTime(appointment){
      this.SELECT_APPOINTMENT(appointment);
      this.$router.push('/appointment/confirm');   
    },
  },
  created: function() {
    // Получить даты
    if (!this.CREATE_APPOINTMENT.selectedUser){
      this.$router.push('/appointment');
      return false;
    }
    this.getFilials();
    let idUser = this.CREATE_APPOINTMENT.selectedUser.id;
    this.picker = this.$moment().format('YYYY-MM-DD');
    this.currentStep = 'date';
    this.dates = [];
    this.freeAppointments = [];

    // Получаем свободные участки на каледнаре для пользователя
    let dates = [];
    this.$http.get(window.config.apiUrl + '/appointment/get-free-appointments?idUser=' + idUser)
      .then((response) => {
        if (response.data){
          for (let schedule in response.data) {
            dates.push(response.data[schedule].date);    
          }
        }
        this.freeAppointments = response.data;
        this.dates = dates;
        this.isLoadedDates = true;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

</script>

<style scoped>

  .list-group-item{
    cursor: pointer;
  }

  .list-group-item-arrow{
    font-size: 16px;
    text-align: right;
  }

  .main-container{
    height: calc(100vh - 300px);
  }

  @media screen and  (max-width: 1200px){

    .main-container{
      height: calc(100vh - 220px);
    }

  }

</style>