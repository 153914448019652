import store from '@/store'
import router from '@/router'
import Peer from 'peerjs';
import eventBus from '../eventBus';

var socket;
var localStream;
let callWaitTimer;
var peer = null;
let conn;
let call = null;

function socketStatus(){
  return socket;
}

function socketConnect(){
  socket = null;
  console.log('Подключение сокетов...')
  if (store.getters.getUser){
    // Устанавливаем соединение
    try {
      socket = new WebSocket(window.config.socketUrl + '/?' + new URLSearchParams({
        'token': store.getters.token,
        'id': store.getters.getUser.id,
        'type': 'customer',
      }).toString());
    } catch (error) {
      console.log('Ошибка при подключении сокетов', error)
    }

    socket.onopen = (event) => {
      console.log('Открыто соединение сокетов')
    }
    
    // Закрытие сокетов
    socket.onclose = (event) => {
      if (event.wasClean) {
        console.log('Соединение закрыто чисто');
      } else {
        console.log('Обрыв соединения'); 
        console.log('Закрыто соединение сокетов');
        socketReconnect();
      }
      socket = null ;
    }

    // Ошибка сокета
    socket.onerror = function(event) {
        console.log(event);
        console.log('Ошибка соединения сокетов')
    };

    // Входящее сообщение сокетов
    socket.onmessage = (event) => {
      let result = JSON.parse(event.data);
      console.log('Входящее сообщение сокетов', result);

      // Ошибка команды
      if (result.status == 'error'){
        // Неверный токен
        if (result.code == 422){
          store.dispatch('logout')
          .then(() => {
            router.push('/login?socket=65')
          }).catch(err => console.log(err))
        }
        // socketReconnect()
      }

      // Успешная команда
      if (result.status == 'success'){

          // Входящее сообщение
          if (result.event == 'receivedMessage'){
            incomingMessage(result);
          }

          // Когда врач прочитывает диалог
          if (result.event == 'isReadMessage'){
            getReadCouponServiceStatus(result.couponService)
          }

          // Отчет о доставленном сообщении
          if (result.event == 'sendMessage'){
            setSendMessageStatus(result.guid);
          }

          // Отчет о оплаченной услуге
          if (result.event == 'isPayment'){
            addNewCouponService(result.couponService);
          }

          // // Входящий вызов
          // if (result.event == 'getIncomingCall'){
          //   console.log('call socket');
          //   this.incomingCall(result.couponService, result.fio, result.service);
          // }

          // Врач принял звонок
          if (result.event == 'getAcceptCall'){
            console.log('Врач принял звонок');
            startCall(result.callId);
            store.dispatch('sendChatNotify', {
              notify: 'Звонок начался', 
              idCouponService: store.getters.currentCouponService
            });
          }

          // Врач отклонил звонок
          if (result.event == 'getRejectCall'){
            userRejectCall(result.couponService, result.completed);
            if (window.device && window.device.platform == 'iOS'){
              document.querySelector('body.ios-body').classList.remove('call');
            }
            if (result.completed){
              store.dispatch('sendChatNotify', {
                notify: 'Врач завершил услугу', 
                idCouponService: store.getters.currentCouponService
              });
            } else {
              store.dispatch('sendChatNotify', {
                notify: 'Врач отклонил звонок', 
                idCouponService: store.getters.currentCouponService
              });
            }
          }

      }
    }
  }
}

// Переподключение сокетов
function socketReconnect(){
  setTimeout(() => {
    if (socket){
      console.log('Закрытие сокетов перед переподключением...')
      socket.close();
      socket = null;
    }
    if (store.getters.getUser){
      socket = null;
      console.log('Переподключение сокетов...')
      socketConnect();
    }
  }, 3000); 
}

// Отключение сокетов
function socketClose(){
  if (socket){
    console.log('Закрытие сокетов по запросу')
    socket.close();
    socket = null;
  }
}

// Начать звонок врачу
function startVideoCall(idCouponService){
  store.dispatch('setCallStatus', 'wait-user');
  store.dispatch('sendChatNotify', {
    notify: 'Начинаем звонок', 
    idCouponService: store.getters.currentCouponService
  });
  console.log('Call to ' + idCouponService)
  let request = {
    event: 'sendIncomingCall', 
    couponService: idCouponService,
    token: store.getters.token,
    type: 'customer',
    id: store.getters.activeUser
  };
  console.log(JSON.stringify(request));
  socket.send(JSON.stringify(request));
  clearTimeout(callWaitTimer);
  callWaitTimer = setTimeout(() => {
      store.dispatch('setCallStatus', 'wait');
      store.dispatch('sendChatNotify', {
        notify: 'Врач не отвечает', 
        idCouponService: store.getters.currentCouponService
      });
  }, window.config.callWaitDuration);

  // Отключаем авто-блокировку экрана
  if (window.cordova){
    if (window.device){
      if (window.device.platform === 'iOS' || window.device.platform === 'Android') {
        window.plugins.insomnia.keepAwake()
      }
    }
  }
}

// Отчет об прочтении диалога врачом
function getReadCouponServiceStatus(idCouponService){
  // Перебираем услуги
  let couponService = store.getters.couponServices.filter(couponService => couponService.id == idCouponService)[0];
  // Перебираем сообщения
  couponService.messages.forEach(function(message){
      // Устанавливаем статус "Отправлено"
      message.read = true;
  })
}

// Входящее сообщение
function incomingMessage(result){
    console.log('Входящее сообщение чата: ', result.data);
    // Добавляем сообщение в чат
    let couponService = store.getters.couponServices.filter(couponService => couponService.id == result.data.idCouponService)[0];
    couponService.messages.push({
        id: Date.now(), 
        text: result.data.message, 
        date: result.data.dateCreate,
        fromUser: result.data.fromUser,
        files: result.files,
        read: result.data.read
    });   
    // Получаем количество непрочитанных
    couponService.unreadCount = result.count;
    // Воспроизводим звук
    let audio = new Audio(require('@/assets/incoming-message.mp3')); 
    audio.play();

    let chatMessages = document.querySelector('.chat-messages')
    if (chatMessages){
      setTimeout(() => {
        eventBus.$emit('scrollChat');
      }, 300);
    }
}

// Событие при успешной отправке сообщения
function setSendMessageStatus(guid){
  // Перебираем услуги
  store.getters.couponServices.forEach(function(service){
    // Перебираем сообщения
    service.messages.forEach(function(message){
      if (message.guid == guid){
        // Устанавливаем статус "Отправлено"
        message.send = true;
      }
    })
  })
}

// Оплата новой услуги
function addNewCouponService(idCouponService){
  console.log('pay ' + idCouponService);
}

// Врач отклонил звонок
function userRejectCall(idCouponService, completed){
  if (completed){
    store.dispatch('setCallStatus', 'completed');
    store.dispatch('setChatCouponServiceAvailable', {idCouponService, status: true});
    
  } else {
    store.dispatch('setCallStatus', 'wait');
  }
  clearTimeout(callWaitTimer);
  console.log('Врач завершил звонок звонок');
  if (document.querySelector('#remoteVideo')){
    document.querySelector('#remoteVideo').srcObject = null;
  }
  if (document.querySelector('#localVideo')){
      document.querySelector('#localVideo').srcObject = null;
  }
  if (localStream){
    call.close();
    localStream.getTracks().forEach(track => track.stop())
  }
  // Воспроизводим звук
  // let audio = new Audio(require('@/assets/losing-call.mp3')); 
  // audio.play();
  // Включаем обратно авто-блокировку экрана
  if (window.cordova){
    if (window.device){
      if (window.device.platform === 'iOS' || window.device.platform === 'Android') {
        window.plugins.insomnia.allowSleepAgain();
      }
    }
  }
}

// Пациент отменил звонок
function customerRejectCall(idCouponService){
  store.dispatch('setCallStatus', 'wait');
  console.log('Пациент отклонил звонок');
    store.dispatch('sendChatNotify', {
    notify: 'Пациент отклонил звонок', 
    idCouponService: store.getters.currentCouponService
  });
  clearTimeout(callWaitTimer);
  document.querySelector('#remoteVideo').srcObject = null;
  if (document.querySelector('#localVideo')){
      document.querySelector('#localVideo').srcObject = null;
  }
  let request = {
      event: 'sendRejectCall', 
      couponService: idCouponService,
      token: store.getters.token,
      type: 'customer',
      id: store.getters.activeUser
  };
  socket.send(JSON.stringify(request));
  // Если поток остался - останавливаем его
  if (localStream){
      call.close();
      localStream.getTracks().forEach(track => track.stop())
  }
  // Воспроизводим звук
  // let audio = new Audio(require('@/assets/losing-call.mp3')); 
  // audio.play();
  // Включаем обратно авто-блокировку экрана
  if (window.cordova){
    if (window.device){
      if (window.device.platform === 'iOS' || window.device.platform === 'Android') {
        window.plugins.insomnia.allowSleepAgain();
      }
    }
  }
}

// Права на видео / аудио успешно получены
function permissionSuccess(callId) {
  console.log('Права получены');
  navigator.mediaDevices.getUserMedia(window.config.mediaConfig).then(function(stream) {
      console.log('Поток получен');
      document.getElementById("localVideo").srcObject = stream;
      if (localStream) {
        console.log('Остановка локального потока');
        localStream.getTracks().forEach(track => track.stop()) 
      }
      localStream = stream;
      call = peer.call(callId, stream);
      call.on('stream', (remoteStream) => {
        console.log('Получен удаленный поток');
        document.getElementById("remoteVideo").srcObject = remoteStream;  
      });
      if (window.device && window.device.platform == 'iOS'){
        document.querySelector('body.ios-body').classList.add('call');
      }
  })
  .catch(function(e){
      console.log('Failed to get local stream. ' + e)
  });
}

// Ошибка получения прав на видео / аудио
function permissionError() {
  alert('Camera permission is not turned on');
}

// Врач принял звонок - начало звонка
function startCall(callId){
    clearTimeout(callWaitTimer);
    store.dispatch('setMutedMode', false);
    store.dispatch('setCallStatus', 'call');
    console.log('Звонок врачу на ' + callId);

    // Соединяемся к peer по id
    conn = peer.connect(callId);
    if (conn){
      console.log('Есть conn')
      conn.on('open', function () {
        console.log('Conn on open')
        // Проверка и установка прав доступа к камере и аудио
        if (window.cordova){
          if (window.device){
            if (window.device.platform === 'iOS') {
              window.cordova.plugins.iosrtc.registerGlobals();
              permissionSuccess(callId);
            }
            if (window.device.platform === 'Android'){
              var permissions = window.cordova.plugins.permissions;
              var permList = [
                  permissions.CAMERA,
                  permissions.RECORD_AUDIO
              ];
              permissions.requestPermissions(permList, function(status) {
                if (status.hasPermission) {
                  permissionSuccess(callId);
                } else {
                  console.log('Debug perm')
                }
              }, permissionError);
            }
          }
        } else {
          permissionSuccess(callId);
        }
      });
    } else {
      alert('No peer connection')
    }
    
}

// Переключить режим микрофона
function toggleMuted(){
  console.log('Режим отключения микровона: ' + (!store.getters.mutedMode));
  let mutedMode = store.getters.mutedMode;
  if (mutedMode){
      localStream.getAudioTracks()[0].enabled = true;
  } else {
      localStream.getAudioTracks()[0].enabled = false;
  }
  store.dispatch('setMutedMode', !mutedMode);
}

// Отправка сообщений сокетов
function socketSend(message){
  if (socket){
    socket.send(message)
  } else {
    socketReconnect()
  }
}

// Подключить peer
function peerConnect(){
  console.log('Подключение к peer...');
  // Создаем обьект подключения
  peer = new Peer(window.config.peerConfig);

  // Соединение peer
  peer.on('connection', connection => {
    console.log('Try connection peer...')
    this.conn = connection;

    connection.on('open', function () {
      console.log('Открыто соединение peer')
    });
    
    connection.on('close', function () {
      console.log('Закрыто соединение peer')
    });
    
    connection.on('error', function (error) {
      console.log('Ошибка соединения peer')
      console.log(error)
    });
  })
      
  peer.on('error', function(err) { 
    console.log('Ошибка peer ' + err )
  });
  
  peer.on('close', err => { 
    console.log('Закрытие peer' + err)
    setTimeout(() => {
      this.peerConnect();
    }, 10000);
  });
  
  // При отключении peer
  peer.on('disconnected', (err) => { 
    console.log('Отключение peer: ' + err + conn)
    setTimeout(() => {
      console.log('Переподключение peer')
      this.peerConnect();
    }, 10000);
  });

  // Получение call Id
  peer.on('open', id => {
    this.callId = id;
    console.log('Получение id peer: ' + id)
  });
}

export default {
  socketClose,
  socketConnect,
  peerConnect,
  socketSend,
  startVideoCall,
  userRejectCall,
  customerRejectCall,
  toggleMuted,
  socketReconnect,
  socketStatus
}