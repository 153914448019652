<template>
  <div class="w-100 d-flex flex-column align-items-center" v-if="promocode">
    <div>
      <h4 class="mb-4" :class="textClasses">{{ promocode }}</h4>
    </div>
    <v-row class="justify-content-center align-items-center" v-if="!$isMobile">
      <v-col cols="12" class="py-1">
        <v-btn
          depressed
          outlined
          block
          :ripple="false"
          color="success darken-1"
          :loading="copyPromocodeLoading"
          @click="copyPromocode()"
        >
<!--          <v-icon>fas fa-copy</v-icon>-->
          Копировать
        </v-btn>
        <v-tooltip bottom color="grey darken-1" v-model="showCopyTooltip">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            ></div>
          </template>
          <span>Скопировано</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" class="py-1">
        <v-btn
          depressed
          block
          outlined
          :ripple="false"
          color="success darken-1"
          :loading="sharePromocodeLoading"
          @click="sharePromocode()"
        >
<!--          <v-icon>fas fa-share-alt</v-icon>-->
          Поделиться
        </v-btn>
      </v-col>
    </v-row>
    <div v-else-if="promocode">
      <v-row><div class="text-center success--text text--darken-1 w-100">Поделиться в соцсетях</div></v-row>
      <v-row>
        <v-col cols="4" class="pa-1">
          <v-btn class="no-hover-underscore" outlined color="success darken-1" :href="`whatsapp://send?text=${promocode}`" target="_blank" data-action="share/whatsapp/share">
            <v-icon>fab fa-whatsapp</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="4" class="pa-1">
          <v-btn class="no-hover-underscore" outlined color="success darken-1" :href="`https://t.me/share/url?url=${promocode}`" target="_blank">
            <v-icon>fab fa-telegram</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="4" class="pa-1">
          <v-btn class="no-hover-underscore" outlined color="success darken-1" :href="`https://vk.com/share.php?url=${promocode}`" target="_blank">
            <v-icon>fab fa-vk</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>

export default {
  name: "InviteFriendPromocode",
  
  data: () => ({
    copyPromocodeLoading: false,
    showCopyTooltip: false,
    sharePromocodeLoading: false,
  }),
  
  methods: {
    // Копирование промокода в буфер
    copyPromocode() {
      const clipboard = navigator.clipboard;
      if (clipboard) {
        this.copyPromocodeLoading = true;
        const clickTime = Date.now();
        clipboard.writeText(this.promocode)
          .then(() => {
            this.showCopyTooltip = true;
            setTimeout(() => this.showCopyTooltip = false, 4000); // Спрятать через 4 секунды
          })
          .catch( error => console.log(error))
          .finally(() => {
            const timeDiff = Date.now() - clickTime;
            if (timeDiff < 1100) { // Поспим до секунды, чтобы анимация лоадера не моргала
              setTimeout(() => this.copyPromocodeLoading = false, 1100 - timeDiff);
            } else {
              this.copyPromocodeLoading = false;
            }
          });
      } else {
        console.log('Браузер не поддерживает возможность работы с буфером обмена');
      }
    },
    
    // Поделиться промокодом
    sharePromocode() {
      if (navigator.share) {
        this.sharePromocodeLoading = true;
        const clickTime = Date.now();
        navigator.share({
          // Тут можно сделать крутой текст типа приходи впервые в клинику а и получай скидку б по промокоду в
          text: this.promocode
        })
          .then()
          .catch((error) => console.log(error) )
          .finally( () => {
            const timeDiff = Date.now() - clickTime;
            if (timeDiff < 1100) { // Поспим до секунды, чтобы анимация лоадера не моргала
              setTimeout(() => this.sharePromocodeLoading = false, 1100 - timeDiff);
            } else {
              this.sharePromocodeLoading = false;
            }
          })
      } else {
        console.log('Браузер не поддерживает возможность поделиться');
      }
    }
  },
  
  props: {
    promocode: {
      type: String,
      default: null
    },
    textClasses: {
      type: Array,
      default: () => []
    }
  }
  
}

</script>

<style scoped>
  .no-hover-underscore:hover {
    text-decoration: none !important;
  }
</style>