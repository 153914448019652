<template>
  <div>
    <v-container> 
      <v-row v-if="!isLoadedSpecials">
        <v-col class="p-0">
          <div>
            <v-skeleton-loader class="mx-0 p-0" type="article"></v-skeleton-loader>
            <v-skeleton-loader class="mx-0 p-0" type="article"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="isLoadedSpecials">
        <v-col cols="12" v-if="!specials.length">
          <v-alert prominent text>
            На данный момент нет свободных врачей.
          </v-alert>
        </v-col>
        <v-col class="py-0" cols=12 v-if="specials.length">
          <v-row>
            <v-col cols="12" class="px-0 py-0">
              <div class="page-search">
                <v-text-field
                  v-model="specialSearch"
                  placeholder="Поиск"
                  filled
                  rounded
                  clearable
                  dense
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <h5>Выберите специальность</h5>
            <v-container>
              <v-row dense>
                <v-col cols="12" class="pt-0" v-for="special in filteredSpecials" :key="special.id" @click="selectSpecial(special.id)">
                  <v-card outlined  rounded >
                    <v-list-item link>
                      <v-list-item-content>
                        <v-list-item-title>{{special.specialName}}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon>fas fa-angle-right</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-card>
                </v-col>
                <v-col cols="12" v-if="specialSearch && specialSearch.length && !filteredSpecials.length">
                  <v-alert prominent text>
                    По вашему запросу ничего не найдено
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-col>
      </v-row>
    </v-container> 
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  data() {
    return {
      popularSpecials: [
        {
          id: 3,
          title: 'Терапевт',
          subtitle: 'Специалист общего профиля',
          icon: 'fas fa-user-md'
        },
        {
          id: 2191,
          title: 'Педиатр',
          subtitle: 'Специалист для детей и подростков',
          icon: 'fas fa-baby'
        },
        {
          id: 2159,
          title: 'Травматолог',
          subtitle: 'Травмы суставов, мышц и костей',
          icon: 'fas fa-user-injured'
        }
      ],
      specialSearch: '',
      specials: [],
      isLoadedSpecials: false,
      selectedSpecial: null,
    }
  },
  computed: {
    filteredSpecials() {
      if (this.specialSearch){
        return this.specials.filter((special) => {
          return special.specialName.toUpperCase().indexOf(this.specialSearch.toUpperCase()) >= 0;
        })
      } else {
        return this.specials;
      }
    }
  },
  methods: {
    ...mapActions([
      'SELECT_SPECIAL'
    ]),
    selectSpecial(idSpecial){
      this.SELECT_SPECIAL(idSpecial);
      this.$router.push('/appointment/select-user');
    },
  },
    created: function() {
      // Получить специальности
      this.$http.get(window.config.apiUrl + '/special?idCustomer=' + this.$store.getters.activeUser)
        .then((response) => {
          if (response.data){
            this.specials = response.data;
            this.isLoadedSpecials = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
}

</script>

<style scoped>

    .list-group-item{
        cursor: pointer;
    }

    .list-group-item-arrow{
        font-size: 16px;
        text-align: right;
    }

    .main-container{
        height: calc(100vh - 300px);
    }

    @media screen and  (max-width: 1200px){

        .main-container{
            height: calc(100vh - 220px);
        }

    }

</style>