export default {
  appointmentSelectSpecial(state, idSpecial) {
    state.createAppointment.selectedSpecial = idSpecial;
  },
  appointmentSelectUser(state, idUser) {
    state.createAppointment.selectedUser = idUser;
  },
  appointmentSelectTime(state, appointment) {
    state.createAppointment.selectedAppointment = appointment;
  },
  appointmentSelectServiceType(state, serviceType) {
    state.createAppointment.selectedServiceType = serviceType;
  },
  setScheduleServices(state, services){
    state.createAppointment.scheduleServices = services;
  }
}