<template>
  <div>
      <v-card
        class="review-item text-center my-4"
        elevation="0"
      >
        <div class="title-container text-center">
          <v-card-subtitle>
            <h3 class="text-center">{{description}}</h3>
          </v-card-subtitle>
          <!-- <v-card-subtitle class="pt-0">
            Оцените работу учереждения, ответив на несколько вопросов. 
          </v-card-subtitle> -->
        </div>
        

      </v-card>
    </div>
</template>

<script>

export default {
  data(){
    return {
      show: false
    }
  },
  props: {
    name: String,
    description: String,
  }

}

</script>

<style scoped>

.title-container .text--primary{
  margin-left: 0.5rem;
}

</style>
