    <template>
      <div>
        <div class="header-mobile">
          <v-container>
              <div class="top-toolbar">
                <div class="navigate-btn" >
                  <v-btn icon @click="toBack">
                    <v-icon>fas fa-angle-left</v-icon>
                  </v-btn>
                </div>
                <div class="page-title">
                  {{title}}
                </div>
                <div class="second-btn">
                </div>
              </div>
            </v-container>
        </div>
        <div>
          <v-container>
            <v-row v-if="loaded">
              <v-col cols=12 class="section-title">
                <h5>Результаты исследований</h5>
              </v-col>
              <v-col cols="12" v-if="getRepresentatives && getRepresentatives.length">
                <v-select
                  v-model="filters.idCustomers"
                  :items="selectCustomers"
                  label="Выбор пациентов"
                  multiple
                  chips
                  hint="Выберите пациентов записи которых вы хотите просмотреть"
                  persistent-hint
                  deletable-chips
                ></v-select>
              </v-col>
              <v-col cols="12" v-if="isEmpty" class="pt-0">
                <v-alert prominent text>
                  На данный момент готовые результаты исследований отсутствуют
                </v-alert>
              </v-col>
              <v-col class="pt-0" cols="12" :key="analysis.id" v-for="analysis in filteredAnalyses">
                <v-card outlined>
                    <v-list-item three-line>
                        <v-list-item-content class="pb-0">
                            <div class="d-flex justify-space-between">
                                <div>
                                    <div class="overline">
                                      <v-chip x-small>
                                        {{ analysis.specialName || 'Исследования' }}
                                      </v-chip>
                                    </div>
                                    <v-list-item-title class=" mb-1">
                                      <v-icon class="mr-1" small style="margin-top: -2px">far fa-calendar-alt</v-icon>{{ analysisFormatDate(analysis.measurementDate) }}
                                    </v-list-item-title>
																		<small style="font-size: 0.9em" v-if="analysis.customerSurname || analysis.customerName || analysis.customerMiddleName">
																			<v-icon class="mr-1" small style="margin-top: -2px">fas fa-user</v-icon>
																			<b class="grey--text text--darken-3">Пациент: </b>
																			{{ analysis.customerSurname }} {{ analysis.customerName }} {{ analysis.customerMiddleName }}
																		</small><br>
                                    <small style="font-size: 0.9em" v-if="analysis.surname || analysis.name || analysis.middleName">
                                      <v-icon class="mr-1" small style="margin-top: -2px">fas fa-user-md</v-icon>
                                      <b class="grey--text text--darken-3">Специалист: </b>
                                      {{ analysis.surname }} {{ analysis.name }} {{ analysis.middleName }}
                                    </small>
                                </div>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                    <v-card-actions class="pl-3 pb-3" >
                        <v-btn class="text-decoration-none"
                            :disabled="!analysis.id"
                            small
                            text
                            link
                            :to="'/view-appointment?idExamination=' + analysis.id"
                            color="primary"
                            :dark="!!(analysis.id)"
                        >Просмотреть</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="!loaded">
                <template v-for="i in 4">
                  <v-col class="" cols="12" md="4" :key="i">
                    <div>
                      <v-skeleton-loader  class="" type="card"></v-skeleton-loader>
                    </div>
                  </v-col>
                </template>
            </v-row>
          </v-container>
        </div>
      </div>
    </template>
    
    <script>
    
    import {AnalysesApiService} from '@/services/api'
    import {mapGetters} from "vuex";
    
    export default {
      name: 'Analyzes',
      data: function() {
        return {
          analyses: [],
          loaded: false,
          title: 'Результаты исследований',
          filters: {
              idCustomers: [],
          }
        }
      },
      methods: {
        toBack() {
          this.$router.push('/')
        },
        // Вернуть отформатированную дату осмпотра
        analysisFormatDate(dateFrom){
            return this.$moment(dateFrom).format("LL, dddd, LT");
        },
      },
      async mounted() {
        try {
          let analyses = await AnalysesApiService.getAnalyses()
          this.analyses = analyses
        } catch (err) {
          this.analyses = null
        } finally {
          this.loaded = true
        }
        this.filters.idCustomers = [this.getUser.id];
      },
      computed: {
        ...mapGetters([
            'getUser',
            'getRepresentatives'
        ]),
        
        isEmpty() {
          if (this.filteredAnalyses){
            return this.filteredAnalyses.length == 0 ? true : false
          } else {
            return true;
          }
        },
        
        filteredAnalyses() {
          if (!this.analyses) {
              return [];
          }
					return this.analyses.filter( val => this.filters.idCustomers.indexOf(val.customerId) !== -1 );
        },
	
				selectCustomers() {
					return [...this.getRepresentatives, this.getUser].map( val => ({
						text: `${val.surname ?? ''} ${val.name ?? ''} ${val.middleName ?? ''}`.trim(),
						value: val.id,
						disabled: false
					}) ).sort( (f, s) => f.value - s.value);
				}
			}
    }
    </script>
    
    <style scoped>
    
    @media screen and  (max-width: 1200px){
    
    }
    
    </style>
