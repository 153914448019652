export default {
  // Получить режим микрофона
  mutedMode: state => state.chat.mutedMode,
  // Получить статус звонка
  callStatus: state => state.chat.callStatus,
  // Получить услуги чата
  couponServices: state => state.chat.couponServices,
  // Получить текущую услугу чата
  currentCouponService: state => state.chat.currentCouponService,
  couponService: (state) => {
    if (state.chat.couponServices.length){
      let couponService = state.chat.couponServices.filter(couponService => couponService.id == state.chat.currentCouponService)[0];
      return couponService;
    } else {
      return false;
    }
  },
  // Получить сообщения активного чата
  activeCouponServiceMessages: (state) => {
    let couponService = state.chat.couponServices.filter(couponService => couponService.id == state.chat.currentCouponService)[0];
    if (couponService){
      return couponService.messages;
    } else {
      return false;
    }
  },
  // Количество непрочитанных диалогов 
  UNREAD_COUNT: (state) => {
    let count = 0;
    state.chat.couponServices.forEach(function(couponService){
      // Если есть непрочитаные сообщения
      if (couponService.unreadCount){
        count++;
      }
    });
    return count;
  }
}