<template>

		<v-row>
			<v-col cols="12">
				<v-card flat>
					<v-list class="mt-3">
					<template v-if="!loading">
						<template v-if="data && data.length">
							<template v-for="session in data">
								<v-card
									:key="session.id"
									outlined
									tile
									class="pa-2"
									style="position: relative"
									:disabled="sessionLoading"
									v-if="session && session.additional"
								>
									<v-col cols="12 d-flex" style="gap: 1rem">
										<template v-if="session.additional && session.additional.device && definedType(session.additional.device.type)">
											<div
												v-if="session.additional.device.type == 'desktop'"
												class="d-flex flex-column align-items-center user-select-none"
												style="width: max-content"
											>
												<div class="text-center mt-1"><v-icon color="grey">fas fa-desktop</v-icon></div>
											</div>
											<div
												v-if="session.additional.device.type == 'smartphone' || session.additional.device.type == 'phablet'"
												class="d-flex flex-column align-items-center user-select-none"
												style="width: max-content"
											>
												<div class="text-center mt-1"><v-icon color="grey">fas fa-mobile</v-icon></div>
											</div>
											<div
												v-if="session.additional.device.type == 'tablet'"
												class="d-flex flex-column align-items-center user-select-none"
												style="width: max-content"
											>
												<div class="text-center mt-1"><v-icon color="grey">fas fa-tablet</v-icon></div>
											</div>
											<div
												v-if="session.additional.device.type == 'tv'"
												class="d-flex flex-column align-items-center user-select-none"
												style="width: max-content"
											>
												<div class="text-center mt-1"><v-icon color="grey">fas fa-tv</v-icon></div>
											</div>
											<div
												v-if="session.additional.device.type == 'feature phone'"
												class="d-flex flex-column align-items-center user-select-none"
												style="width: max-content"
											>
												<div class="text-center mt-1"><v-icon color="grey">fas fa-phone-alt</v-icon></div>
											</div>
											<div
												v-if="session.additional.device.type == 'wearable'"
												class="d-flex flex-column align-items-center user-select-none"
												style="width: max-content"
											>
												<div class="text-center mt-1"><v-icon color="grey">fas fa-clock</v-icon></div>
											</div>
										</template>
										
										<div class="d-flex flex-column" :style="{width: $vuetify.breakpoint.mdAndUp ? `80%` : `70%`}">
											<div class="fill-height w-100" v-if="session.additional && session.additional.client && session.additional.client.type == 'browser'">
												<span class="text-body-1 font-weight-bold">Браузер:&nbsp;</span>
												<span>{{session.additional.client.name}}</span>
												<span
													class="text-caption"
													v-if="session.additional.client.version"
												>
													&nbsp;{{session.additional.client.version}}
												</span>
											</div>
											<div class="fill-height w-100" v-if="session.additional && session.additional.os && session.additional.os.name">
												<span class="text-body-1 font-weight-bold">ОС:&nbsp;</span>
												<span>{{session.additional.os.name}}</span>
												<span
													v-if="session.additional && session.additional.os.version"
												>
													&nbsp;{{!!session.additional ? session.additional.os.version : ''}}
												</span>
												<span
													class="text-caption"
													v-if="session.additional && session.additional.os.platform"
												>
													&nbsp;{{!!session.additional ? session.additional.os.platform : ''}}
												</span>
											</div>
											<div
												class="fill-height w-100"
												v-if="session.additional && session.additional.device && (session.additional.device.brand || session.additional.device.model)"
											>
												<span class="text-body-1 font-weight-bold">Устройство:&nbsp;</span>
												<span>{{session.additional.device.brand || ""}} {{session.additional.device.model || ""}}</span>
											</div>
											<div
												class="fill-height w-100"
												v-if="session.lastActive"
											>
												<span class="text-caption primary--text text--darken-1">{{humanDate(session.lastActive)}}</span>
											</div>
										</div>
									</v-col>
									<template v-if="$vuetify.breakpoint.mdAndUp">
										<div text
                                            class="text-caption text-decoration-underline primary--text close-session"
                                            style="position: absolute; right: 1rem; top: 1rem;"
                                            @click="closeSession(session.id)"
										>
											Завершить
										</div>
									</template>
									<template v-else>
										<v-btn
											text
											class="close-session-cross"
											color="primary"
											@click="closeSession(session.id)"
										>
											<v-icon small>fas fa-times</v-icon>
										</v-btn>
									</template>
								</v-card>
							</template>
						</template>
						<template v-else>
							<v-alert prominent text>
								Нет других сеансов
							</v-alert>
						</template>
					</template>
					<template v-else>
						<v-card class="text-center w-100 mt-10" flat>
							<v-progress-circular indeterminate color="primary" :size="100" :width="2"></v-progress-circular>
						</v-card>
					</template>
				</v-list>
				</v-card>
			</v-col>
			<v-col cols="12">
				<v-btn
					depressed
					@click="dropSessions"
					:loading="dropSessionLoader"
					block
					:disabled="!data || !data.length"
					v-if="data && data.length"
				>
					<v-icon small left>fas fa-times</v-icon>
					Завершить все сеансы
				</v-btn>
			</v-col>
		</v-row>
		
	
</template>

<script>

export default {
	data() {
		return {
			loading: true,
			dropSessionLoader: false,
			data: [],
			
			/**
			 * Время последнего получения данных
			 * @var {Number|null}  */
			lastFetchTime: null,
			sessionLoading: false
		}
	},
	
	created() {
	},
	
	computed: {},
	
	mounted() {
		this.getSessions();
		setInterval(() => { // Обновляем данные раз в минуту
			this.getSessions();
		}, 60000);
	},
	
	methods: {
		/**
		 * Получает список сессий
		 */
		getSessions() {
			this.loading = true;
			this.$http(window.config.apiUrl + '/customers/get-sessions')
				.then(({data}) => {
					if (data.status == 'success') {
						this.data = data.sessions
					}
					// TODO статус еррор
				})
				.catch(error => console.log(error))
				.finally(() => {
					this.loading = false;
					this.lastFetchTime = this.$moment().unix()
				});
		},
		
		/**
		 * Дропает все сессии кроме текущей
		 */
		dropSessions() {
			this.dropSessionLoader = true;
			this.$http.post(window.config.apiUrl + '/customers/drop-sessions')
				.then(({data}) => {
					if (data.status == 'success') {
						this.data = [];
					}
				})
				.catch(error => console.log(error))
				.finally(() => this.dropSessionLoader = false);
		},
		
		humanDate(date) {
			let res = this.$moment(date).fromNow();
			return (res == 'Invalid date') ? '' : res;
		},
		
		/**
		 * Закрывает сессию по id
		 * @param {Integer} id
		 * @return void
		 */
		closeSession(id) {
			this.sessionLoading = true;
			this.$http.post(window.config.apiUrl + '/customers/drop-session', {id: id})
				.then(({data}) => {
					if (data.status == 'success') {
						this.data = [...this.data.filter( val => val.id !== data.id )]
					}
				} )
				.catch( error => console.log(error) )
				.finally( () => this.sessionLoading = false );
		},
		
		/**
		 * Возвращает true если для типа объявлена специальная иконка и название
		 * @param {String} type
		 * @return Boolean
		 */
		definedType(type) {
			const definedTypes = [
				'desktop',
				'smartphone',
				'phablet',
				'tablet',
				'tv',
				'feature phone',
				'wearable'
			];
			return (definedTypes.indexOf(type) !== -1) ? true : false;
		}
	}
}

</script>

<style>

.close-session {
	opacity: 0;
	user-select: none;
	transition: opacity 0.2s;
}

.v-card:hover .close-session {
	opacity: 1;
	cursor: pointer;
}

.close-session-cross {
	position: absolute;
	top: 1rem;
	right: 0;
}

</style>