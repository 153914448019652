export default date => {
  let humanDate = new Date(date)
  let monthNames = [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря'
  ]
  let day = humanDate.getDate()
  let monthIndex = humanDate.getMonth()
  let year = humanDate.getFullYear()
  return day + ' ' + monthNames[monthIndex] + ' ' + year
}
