<template>
  <div>
    <div class="header-mobile d-none d-lg-block" >
      <v-container>
        <div class="top-toolbar">
          <div class="navigate-btn" >
            <v-btn icon @click="goToBack">
              <v-icon>fas fa-angle-left</v-icon>
            </v-btn>
          </div>
          <div class="page-title">
            {{title}}
          </div>
          <div class="second-btn">
            <v-btn icon @click="startVideoCall" v-if="this.currentCouponService && this.couponService.onlineVideo && couponService.serviceAvailableStatus">
              <v-icon>fas fa-video</v-icon>
            </v-btn> 
          </div>
        </div>
      </v-container>
    </div>
    <div>
      <v-container class="py-0">
        <v-row>
          <v-col cols="12" v-if="!couponServices.length && !servicesLoading" class="pt-0">
            <v-alert prominent text>
              На данный момент у вас нет оформленных услуг телемедицины
            </v-alert>
          </v-col>
          <v-col cols="12" v-if="servicesLoading" class="pt-0">
            <v-sheet light :color="`grey lighten-4`" class="p-0" >
              <v-skeleton-loader class="mx-0" type="article"></v-skeleton-loader>
            </v-sheet>
          </v-col>
          <v-col v-if="couponServices.length && !servicesLoading" class="py-0">
            <div class="wrap" :class="{'show-coupon-service' : this.currentCouponService}">
              <div class="chat">
                <div class="services">
                  <ServiceList 
                    :couponServices="couponServices"
                    :servicesLoading="servicesLoading"
                  />
                </div>
                <div class="messages">
                  <ChatInfo/>
                  <ChatMessages :currentVideoCouponService="currentVideoCouponService"/>
                  <ChatControl v-if="this.currentCouponService"/>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-dialog v-model="allowCameraDialog" persistent max-width="400">
      <v-card>
        <v-card-title>Разрешите доступ</v-card-title>
        <v-card-text>
            Чтобы использовать услуги телемедицины, нужен доступ к камере и микрофону
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn @click="allowCameraDialog = false" text rounded color="grey" smalldark>Отмена</v-btn>
          <v-btn @click="tryAllowCameraAccess" text rounded color="primary" dark>Повторить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>

import eventBus from '../../eventBus';
import store from '@/store'
import socket from '@/services/socket.js'
import moment from 'moment';

import ServiceList from './ServiceList'
import ChatInfo from './ChatInfo'
import ChatMessages from './ChatMessages'
import ChatControl from './ChatControl'
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'Chat',
    components: {
        ServiceList,
        ChatInfo,
        ChatMessages,
        ChatControl,
    },
    created: function(){

    },
    beforeDestroy() {
      clearInterval(this.serviceStatusInterval);
    },
    data () {
      return {
        servicesLoading: false,
        currentVideoCouponService: 0,
        title: 'Консультации онлайн',
        serviceStatusInterval: null,
        allowCameraDialog: false
      }
    },
    methods: {
        ...mapActions([
            'setMutedMode',
            'setChatCouponServiceAvailable'
        ]),
        checkServiceAvailable(){
          if (this.couponService){
            // Если услуга еще не началась
            if (moment().unix() >=  moment(this.couponService.dateFrom).unix() &&  moment().unix() <= moment(this.couponService.dateTo).unix()){
              this.setChatCouponServiceAvailable({idCouponService: this.currentCouponService, status: true})
            } else {
             this.setChatCouponServiceAvailable({idCouponService: this.currentCouponService, status: false})
            }
          }
        },
        goToCameraAccessHelp() {
   
        },  
        goToBack() {
          eventBus.$emit('goToBack');
        },
        // Вернутся назад
        toBack: function(){
            this.$router.push('/');
        },
        // Начать видео-звонок
        startVideoCall() {
          eventBus.$emit('startVideoCall', this.currentCouponService);
        },  
        tryAllowMediaAccess(){
          this.allowCameraDialog = false;
          // Проверка доступа к камере
          navigator.mediaDevices.getUserMedia(window.config.mediaConfig)
            .then(function(stream) {
                console.log('Есть доступ к камере');
                // Останавливаем поток
                stream.getTracks().forEach(function(track) {
                    track.stop();
                });
            })
            .catch((e) => {
                this.allowCameraDialog = true;
                console.log(e)
            });
        },
        tryAllowCameraAccess(){
          if (window.cordova){
            if (window.device){
              if (window.device.platform === 'iOS') {
                window.cordova.plugins.iosrtc.registerGlobals();
                console.log('Успешное получение прав ios');
                this.tryAllowMediaAccess();
              }
              if (window.device.platform === 'Android'){
                var permissions = window.cordova.plugins.permissions;
                var permList = [
                    permissions.CAMERA,
                    permissions.RECORD_AUDIO
                ];
                permissions.requestPermissions(permList, (status) => {
                  if (status.hasPermission) {
                  console.log('Успешное получение прав Android');
                  this.tryAllowMediaAccess();
                  } else {
                    console.log('Debug perm')
                  }
                }, function(){
                  console.log('Нет прав Android')
                });
              }
            }
          } else {
            this.tryAllowMediaAccess();
          }

        },
        // Получить список услуг чата
        getCouponServices(){
            this.$http.post(window.config.apiUrl + '/chat/get-coupon-services', {
                'id': store.getters.activeUser
            })
            .then((response) => {
                this.$store.dispatch('setCouponServices', response.data);
                this.servicesLoading = false;
            })
            .catch((error) => {
                console.log(error);
            });
        },
       
    },
    mounted: function(){
      // Получить список услуг чата
      this.getCouponServices();
      // Проверяем статус доступности активной услуги чата
      this.checkServiceAvailable();
      this.serviceStatusInterval = setInterval(() => {
        this.checkServiceAvailable();
      }, 10000);

      // Проверка доступа к камере
      this.tryAllowCameraAccess();

    },
    computed: {
      ...mapGetters([
        'callStatus',
        'couponServices',
        'couponService',
        'currentCouponService',
        'mutedMode'
      ]),
    }
}
</script>

<style scoped>

.wrap {
    display: flex;
    height: 100%;
    flex-direction: column;
    background: #fff;
    border-radius: 10px;
}

.services {
    background: #fafafa;
    width: 400px;
    padding: 8px;
    user-select: none;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-right: 10px;
}

.messages {
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 10px;
}

.chat {
  display: flex;
  height: calc(100vh - 295px);
}

@media screen and  (max-width: 1200px){

    .chat{
        height: 100%;
    }

    .show-coupon-service .chat {
        display: flex;
        height: calc(100vh - 206px);
    }

    .chat .service-list{
        overflow:hidden;
    }

    .show-coupon-service .chat .service-list{
        overflow-y: auto;
    }

    .services {
        width: 100%;
        padding: 0;
        background: #fff;
        margin: 0;
    }

    .show-coupon-service .services{
        display:none;
    }

    .messages{
        display: none;
    }

    .show-coupon-service .messages{
        display: flex;
    }

}

</style>
