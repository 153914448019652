<template>
    <div class="service-list">
        <div v-if="servicesLoading">
            <v-skeleton-loader v-for="n in 4" :key="n"
                height="94"
                type="list-item-three-line"
            >
            </v-skeleton-loader>
        </div>
        <div v-else>
            <div v-if="couponServices.length">
                
                <v-list three-line class="py-0 v-item--active light--text">
                    <ServiceItem 
                        v-for="(couponService, index) in couponServices"
                        :key="couponService.id"
                        :index="index"
                        :couponService="couponService"
                    /> 
                </v-list>
            </div>
             <div v-if="!couponServices.length">
                <v-alert color="grey darken-1" text>
                    Нет оплаченных услуг для отображения
                </v-alert>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ServiceItem from './ServiceItem.vue'

export default {
    name: 'ServiceList',
    components: {
        ServiceItem,
    },
    props: [
        'couponServices',
        'servicesLoading'
    ],
}
</script>

<style scoped>
  
.service-list {
    overflow-y: scroll;
    height: 100%;
}

.empty-coupon-services{
    background: #f7f7f8;
    margin: 30px;
    padding: 10px 15px;
    border-radius: 50px;
    text-align: center;
    color: #959595;
    color: #7e7e7e;
}

</style>