<template>
  <v-container>
    <v-row class="calendar-btn-container">
      <v-col
        md="5"
        offset-md="1"
        v-if="$mq !== 'sm'"
      >
        <h5>Выберите время приема</h5>
      </v-col>
      <v-col
        md="5"
        sm="12"
        xs="12"
        :class="[$mq !== 'sm' ? 'text-align-right' : '']"
      >
        <DatePicker :changeHandler="clickCalendarHandler" :getAllowedDates="getAllowedDates"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="10"
        offset-md="1"
        sm="12"
        xs="12"
        class="calendar-line"
        :class="[$mq !== 'sm' ? 'min' : '']"
      >
        <div class="btn prev-btn" @click="prevBtnClickHandler()"><i class="fas fa-chevron-left"></i></div>
        <div 
          class="calendar-line-item"
          @click="clickDateHandler(date.fullDate)" 
          v-for="(date, index) in dates" 
          :key="index" 
          :class="[
              date.disabled ? 'disabled' : '', 
              date.active ? 'active' : '', 
              index === 0 ? 'first-day' : '',
              $mq === 'sm' ? 'small' : ''
            ]"
        >
          <span class="day">{{date.day}} </span>
          <br v-if="$mq === 'sm'">
          <span class="date">{{date.date}} </span>
          <span class="month" v-if="$mq !== 'sm'">{{date.month}}</span>
        </div>
        <div class="btn next-btn" @click="nextBtnClickHandler()"><i class="fas fa-chevron-right"></i></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'
import moment from 'moment'

import DatePicker from './DatePicker.vue'

export default {
  data(){
    return {
      startDate: '',
      // объект для отображения дня недели
      days: {
        1:"Пн", 
        2:"Вт",
        3:"Ср",
        4:"Чт",
        5:"Пт",
        6:"Сб",
        0:"Вс",
      },
      // объект для отображения месяца
      months: {
        0:"янв", 
        1:"фев",
        2:"мар",
        3:"апр",
        4:"май",
        5:"июн",
        6:"июл",
        7:"авг",
        8:"сен",
        9:"окт",
        10:"ноя",
        11:"дек",
      },
      // массив дат для отображения
      dates: [],
    }
  },
  props: {
    // объект с датами под конкретного пользователя
    activeDates: Object,
    // функция определения неактивных дат в дейтпикере
    getAllowedDates: Function,
  },
  created(){
    // установка текущей даты
    if (!this.currentDate) {
      const date = moment(new Date).format(moment.HTML5_FMT.DATE)
      this.$store.commit('setDate', date)
      localStorage.date = date
    }
    // установка первой даты для отображения
    this.startDate = new Date(this.currentDate)
    // формирование массива дат для отображения
    this.changeDates(this.startDate)
  },
  methods: {
    // обработчик выбора даты из календаря
    clickCalendarHandler(date){
      // обновление даты в сторе
      const formatDate = moment(date).format(moment.HTML5_FMT.DATE)
      this.$store.commit('setDate', formatDate)
      localStorage.date = formatDate
      // установка первой даты для отображения
      this.startDate = date
      // сообщает родителю об изменении даты, для подгрузки новых специалистов
      this.$emit('change-date')
      // формирование массива дат для отображения
      this.changeDates(this.startDate)
    },
    // обработчик выбора даты
    clickDateHandler(date){
      // обновление даты в сторе
      const formatDate = moment(date).format(moment.HTML5_FMT.DATE)
      this.$store.commit('setDate', formatDate)
      localStorage.date = formatDate
      // сообщает родителю об изменении даты, для подгрузки новых специалистов
      this.$emit('change-date')
      // формирование массива дат для отображения
      this.changeDates(this.startDate)
    },
    // обработчик нажатия переключения на следующую неделю
    nextBtnClickHandler(){
      // установка первой даты для отображения
      this.startDate.setDate(this.startDate.getDate() + 7)
      // формирование массива дат для отображения
      this.changeDates(this.startDate)
    },
    // обработчик нажатия переключения на предыдущую неделю
    prevBtnClickHandler(){
      // проверка, что бы не отображать прошедшие даты
      const newDate = new Date(this.startDate)
      newDate.setDate(this.startDate.getDate() - 7)
      
      this.startDate.setDate(this.startDate.getDate() - 7)
      // устанавливаем текущую дату в качестве первой, если запрос на отображение прошедших дат
      if (Date.now() > newDate.getTime()) {
        this.startDate = new Date()
      }
      // формирование массива дат для отображения
      this.changeDates(this.startDate)
    },
    // формирование массива дат для отображения
    changeDates(date){
      this.dates = []
      for (let i=0; i<=6; i++) {
        const newDate = new Date(date)

        // прибавляем дни к переданной дате
        newDate.setDate(date.getDate() + i)

        const formatDate = moment(newDate).format(moment.HTML5_FMT.DATE)

        // статус "активная", если совпадает с сегодняшней датой
        const active = (formatDate === this.currentDate)

        // если передано расписание конкретного пользователя, статус "отключена" для даты,
        // которой нет в переданном расписании
        let disabled = false
        if (
          this.activeDates === null || 
          (this.activeDates && !this.activeDates[formatDate])) 
          {disabled = true}

        // добавляем объект даты в массив отображаемых дат
        this.dates.push ({
          disabled,
          active,
          fullDate: new Date(newDate),
          date: newDate.getDate(),
          day: this.days[newDate.getDay()],
          month: this.months[newDate.getMonth()]
        })

      }
    }
  },
  computed: mapGetters(['width', 'currentDate', 'users']),
  watch: {
    activeDates: function(){
      this.changeDates(new Date(this.currentDate))
    }
  },
  components: {
    DatePicker
  },

}
</script>

<style scoped>
  .calendar-btn-container{
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
  .calendar-line{
    display: flex;
    justify-content: center;
    border-bottom: 1px solid rgba(204, 204, 204, 0.5);
  }
  .min{
    padding-left: 0;
    padding-right: 0;
    justify-content: space-between;
  }
  .calendar-line-item{
    text-align: center;
    padding: 0.7rem;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    border-left: 1px solid rgba(204, 204, 204, 0.5);
  }
  .calendar-line-item.first-day{
    border-left: none;
  }
  .calendar-line-item.active{
    color: var(--primary-color);
    cursor: default;
  }
  .calendar-line-item.disabled{
    color: #ccc;
    cursor: default;
  }
  .calendar-line-item .day{
    color: rgb(124, 124, 124);
  }
  .disabled .day{
    color: #ccc;
  }
  
  .btn{
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--primary-color);
  }

  .calendar-line-item.small{
    border: none;
    padding: 0.6rem;
  }
  .calendar-line-item.small .day{
    font-size: 1rem;
  }

  .text-align-right{
    text-align: right;
  }
</style>