<template>
    <div>
        <div class="header-mobile">
            <v-container>
            <div class="top-toolbar">
                <div class="navigate-btn">
                    <v-btn icon @click="toBack" >
                        <v-icon>fas fa-angle-left</v-icon>
                    </v-btn>
                </div>
                <div class="page-title">
                    {{title}}
                </div>
                <div class="second-btn">
                   
                </div>
            </div>
            </v-container>
        </div>
        <div>
            <v-container>
                <v-row>
                    <v-col cols="12">       
                        <h5>Оплата банковской картой</h5>
                        <p>После размещения заказа будет произведён сбор товара. Затем вам будет отправлена ссылка для оплаты в СМС и на e-mail.</p>
                        <p>Оплата происходит через ПАО СБЕРБАНК с использованием Банковских карт следующих платежных систем:</p>
                        <ul>
                            <li>МИР</li>
                            <li>VISA International</li>
                            <li>Mastercard Worldwide</li>
                            <li>JCB</li>
                        </ul>
                        <p>Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платежный шлюз ПАО СБЕРБАНК. Соединение с платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIRAccept, J-Secureдля проведения платежа также может потребоваться ввод специального пароля.</p>
                        <p>
                            <v-alert
                                icon="mdi-shield-lock-outline"
                                prominent
                                text
                                type="success"
                                >
                                Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введенная информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платежных систем МИР, Visa Int., MasterCard Europe Sprl, JCB
                            </v-alert>
                        </p> 
                        <p class="text-center pt-6">
                            <img src="@/assets/layout/paycard.png" height=20 alt="">
                        </p>
                    </v-col>
                </v-row>
            </v-container>  
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            title: 'Процесс оплаты',
            dialog: false,
        }
    },
    created: function() {
        // Событие отправки сообщения
        this.$eventBus.$emit('setTitle', this.title);
    },
    methods: {
        // Вернутся назад
        toBack: function(){
            this.$router.push('/');
        },
    }
}

</script>

<style scoped>

.main-container{
    height: calc(100vh - 242px);
}

@media screen and  (max-width: 1200px){

    .main-container{
        height: calc(100vh - 147px);
    }

}

</style>