<template>
  
  <v-card flat>
    <v-expand-transition>
      <v-btn
        depressed
        block
        color="success"
        v-show="showInviteFriendButton"
        @click="loadPromocode()"
      >
        Пригласить друга<v-icon small right>fas fa-user-plus</v-icon>
      </v-btn>
    </v-expand-transition>
    <div class="d-flex justify-content-center">
      <v-expand-transition>
        <v-alert
          outlined
          color="success"
          text
          v-if="showPromo"
          class="pa-0 w-100 d-flex justify-content-center m-0"
        >
          <v-card
            flat
            color="transparent"
            max-width="300px"
            class="px-2 py-1 w-100"
          >
            <v-card-title>
              <span class="text-body-1 success--text text--darken-1 d-flex flex-column align-items-center w-100">
                <span>Поделитесь своим</span>
                <span>промокодом с друзьями</span>
              </span>
            </v-card-title>
            <v-card-text >
              <div class="w-100 d-flex justify-content-center">
                <template v-if="promoLoading">
                  <v-progress-circular indeterminate color="success darken-1" size="30" v-show="promoLoading"></v-progress-circular>
                </template>
                <template v-else>
                  <InviteFriendPromocode :promocode="promocode" :text-classes="['success--text', 'text--darken-3', 'font-weight-bold']" />
                </template>
              </div>
            </v-card-text>
          </v-card>
        </v-alert>
      </v-expand-transition>
    </div>
  </v-card>
  
  
</template>

<script>

import InviteFriendPromocode from '@/views/Information/InviteFriendPromocode';

export default {
  name: "InviteFriend",
  
  components: {
    InviteFriendPromocode
  },
  
  computed: {
    showInviteFriendButton() {
      return !this.showPromo;
    }
  },
  
  data: () => ({
    showPromo: false,
    promoLoading: false,
    retryCount: 0,
    maxRetryCount: 5,
    promocode: null
  }),
  
  methods: {
    
    // Загрузка промокода с бека
    loadPromocode() {
      this.promoLoading = true;
      this.showPromo = true;
      const startTime = Date.now();
      
      this.$http.post(window.config.apiUrl + '/customer-bonuses/get-friend-invite-code')
        .then( ({data}) => {
          if (data.status == 'success') {
            const timeDiff = Date.now() - startTime;
            if (timeDiff < 1000) { // Убирает моргание лоадера
              setTimeout(() => {
                this.promocode = data.promocode;
                this.promoLoading = false;
              }, 1000 - timeDiff);
            }
          } else {
            // Пытаемся еще раз получить промокод
            if (this.retryCount < this.maxRetryCount) {
              this.retryCount++;
              setTimeout(this.loadPromocode, 1000); // Попробуем еще раз через секунду
            }
          }
        })
        .catch( error => console.log(error) );
    },
  },
}

</script>

<style scoped>

</style>