import Vue from 'vue'
import Vuex from 'vuex'

import state from './state';
import mutations from './mutations/mutations';
import actions from './actions/actions';
import getters from './getters';

import review from './review';

Vue.use(Vuex)
export default new Vuex.Store({
  state,
  review,
  mutations,
  actions,
  getters
})