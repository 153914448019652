<template>
    <div class="chat-info" v-if="couponService && this.currentCouponService">
        <v-list two-line class="info-item">
            <v-list-item class="px-0">
                <div class="pr-2 d-block d-lg-none">
                    <v-btn icon @click="goToBack">
                        <v-icon>fas fa-angle-left</v-icon>
                    </v-btn>
                </div>
                <v-list-item-avatar class="mr-3">
                    <v-img position="top" :src="getUserPhoto(couponService.idUser)"></v-img>
                </v-list-item-avatar>
                <v-list-item-content> 
                    <v-list-item-title v-text="couponService.userName"></v-list-item-title>
                    <small class="grey--text">{{couponService.serviceName}}</small>
                </v-list-item-content>
                <div class="pl-2 d-block d-lg-none">
                    <v-btn icon @click="startVideoCall" v-if="this.currentCouponService && this.couponService.onlineVideo && couponService.serviceAvailableStatus">
                        <v-icon>fas fa-video</v-icon>
                    </v-btn> 
                </div>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import eventBus from '../../../eventBus';
import {mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters([
            'couponService',
            'currentCouponService'
        ]),
    },
    methods: {
        getUserPhoto(idUser){
            return window.config.apiUrl + '/file/get-user-photo?idUser=' + idUser
        },
        startVideoCall() {
            eventBus.$emit('startVideoCall', this.currentCouponService);
        },
        goToBack(){
            this.$store.dispatch('setCurrentCouponService', null);
        }
    }
}
</script>

<style scoped>

.chat-info{
    background: #f7f7f8;
    overflow: hidden;
    top: 56px;
    z-index: 2;
    width: 100%;
    left: 0;
    padding: 0;
    border-bottom: 1px solid #efeded;
    padding-bottom: 8px;
}

@media screen and  (max-width: 1200px){

    .info-item{
        padding: 0;
    }

    .chat-info {
        padding: 0;
        padding-bottom: 10px;
        background: #ffffff;
    }
}

</style>