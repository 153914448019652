<template>
<!--  <v-card flat>
    <h4 class="d-flex align-items-center justify-content-center w-100" style="gap: 0.25rem">
      <span class="success&#45;&#45;text text&#45;&#45;darken-1">Бонусный баланс:&nbsp;</span>
      <template v-if="balanceLoading">
            <span>
              <v-skeleton-loader type="chip" style="display: inline-block;"></v-skeleton-loader>
            </span>
      </template>
      <template v-else>
        <span class="font-weight-black primary&#45;&#45;text">{{ balance }}<span style="font-size: 90%">₽</span></span>
      </template>
    </h4>
  </v-card>-->
  <v-alert color="grey lighten-1"  text class="m-0">
    <v-card flat color="transparent">
      <h4 class="d-flex flex-column align-items-center justify-content-center w-100" style="gap: 0.25rem">
        <span class="text--darken-1 text-body-1">Бонусный баланс</span>
        <template v-if="balanceLoading">
            <span>
              <v-skeleton-loader type="chip" style="display: inline-block;"></v-skeleton-loader>
            </span>
        </template>
        <template v-else>
          <span class="font-weight-black primary--text ">{{ balance }}<span style="font-size: 90%">₽</span></span>
        </template>
      </h4>
    </v-card>
  </v-alert>
</template>

<script>

export default {
  name: "BonusBalance",
  
  data: () => ({
    balanceLoading: false,
    balance: null,
  }),
  
  mounted() {
    this.balanceLoading = true;
    this.$http(window.config.apiUrl + '/customer-bonuses/get-balance')
      .then( ({data}) => {
        if (data.status == 'success') {
          this.balance = data.balance;
          this.balanceLoading = false;
        }
      } )
      .catch( error => console.log(error) );
  },
  
}

</script>

<style scoped>

</style>