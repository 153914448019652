<template>
  <div>
    <div v-if="!message.isNotify">
      <div class="wrap" :class="!message.fromUser ? 'outgoing' : 'incoming'">
          <div class="message">
              <div class="content">
                <div class="files">
                  <MessageFile 
                    v-for="messageFile in message.files" 
                    :key="messageFile.guid"
                    :messageFile="messageFile"
                  />
                </div>
                <div class="text">{{message.text}}</div>
              </div>
              <div class="message-info">
                <div class="time" :title="this.messageDate">
                  {{messageTime}}
                </div>
                <div v-if="!message.fromUser">
                  <div class="status" v-if="!message.send && !message.read && message.guid">
                    <i class="far fa-clock"></i>
                  </div>
                  <div class="status send" v-if="(message.send || !message.guid) && !message.read" title="Доставлено">
                    <i class="fas fa-check"></i>
                  </div>
                  <div class="status read" v-if="message.read" title="Прочитано">
                    <i class="fas fa-check-double"></i>
                  </div>
                </div>
              </div>
          </div>
      </div>    
    </div>
    <div v-if="message.isNotify" class="text-center chat-notify">
      <v-chip class="ma-2" small color="grey lighten-4" :title="messageDate">
        {{messageTime}}, {{message.text}}
      </v-chip>
    </div>
  </div>
</template>

<script>

import MessageFile from './MessageFile'
import moment from 'moment';

export default {
  props: [
    'message'
  ],
  computed: {
    // Получить время сообщения
    messageTime: function(){
      return moment(this.message.date).format('HH:mm');
    },
    // Получить дату сообщения
    messageDate: function(){
      return moment(this.message.date).format('LL');
    }
  },
  components: {
    MessageFile
  }
}
</script>

<style scoped>

.wrap {
  margin: 15px 0;
  padding: 0 60px;
}

.incoming {
  display: flex;
  justify-content: flex-start;
}

.message {
  max-width: 80%;
  width: fit-content;
  padding: 12px;
  border-radius: 6px;
  color: #fff;
  position: relative;
  display: flex;
  word-break: break-word;
  font-size: 14px;
}

.chat-messages-video-with-chat .message{
  max-width: 90%;
}

.chat-messages-video-with-chat .wrap{
  padding: 0 10px;
}

.incoming .message {
  background: #0288d1 ;
  transition: .2s ease-in-out background-color;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.message-info {
  width: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
}

.time {
  font-size: 10px;
  opacity: .6;
  user-select: none;
  padding-top: 2px;
  white-space: nowrap;
}

.wrap.incoming .message::after {
  content: '';
  width: 7px;
  height: 7px;
  position: absolute;
  top: 0;
  border: 10px solid transparent;
  border-top: 10px solid #2b97bf;
  border-right: 10px solid #2b97bf;
  transform: rotate(0deg);
  left: -8px;
  transition: .2s ease-in-out border-color;
  user-select: none;
}

.wrap.incoming ::selection{
  background: #8dd5f0;
}

.wrap.incoming ::-moz-selection{
  background: #8dd5f0;
}

.wrap.incoming .message:hover{
  background: #0073b1;
}

.wrap.outgoing .message:hover{
  background: #e9e9e9;
}

.wrap.outgoing .message::after{
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  right: -8px;
  border: 10px solid transparent;
  border-top: 10px solid #f7f7f8;
  border-right: 10px solid #f7f7f8;
  transform: rotate(270deg);
  transition: .2s ease-in-out border-color;
  user-select: none;
}

.wrap.incoming .message::after{
  content: '';
  width: 7px;
  height: 7px;
  position: absolute;
  top: 0;
  border: 10px solid transparent;
  border-top: 10px solid #0288d1;
  border-right: 10px solid #0288d1;
  transform: rotate(0deg);
  left: -8px;
  transition: .2s ease-in-out border-color;
  user-select: none;
}

.wrap.incoming .message:hover::after{
  border-top: 10px solid #0073b1;
  border-right: 10px solid #0073b1;
}

.wrap.outgoing .message:hover::after{
  border-top: 10px solid #e9e9e9;
  border-right: 10px solid #e9e9e9;
}

.wrap.outgoing{
  display: flex;
  justify-content: flex-end;
}

.wrap.outgoing .message {
  background: #f7f7f8;
  color: #595959;
  transition: .2s ease-in-out background-color;
}

.status {
  font-size: 10px;
  opacity: .8;
  user-select: none;
}

.status.read{
  color: #2b97bf;
}

.files{
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1300px){
  .wrap {
    padding: 0 30px;
  }

  .wrap .message {
    max-width: 70%;
  }
}

@media screen and (max-width: 1200px){
  .wrap {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1070px){
  .wrap .message {
    max-width: 80%;
  }
}

@media screen and (max-width: 800px){
  .wrap .message {
    max-width: 90%;
  }
}

</style>