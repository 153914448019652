<template>
	<div>
		<div class="header-mobile">
			<v-container>
				<div class="top-toolbar">
					<div class="navigate-btn" >
						<v-btn icon  @click="toBack" >
							<v-icon>fas fa-angle-left</v-icon>
						</v-btn>
					</div>
					<div class="page-title">
						{{title}}
					</div>
					<div class="second-btn">
						<v-btn icon color="white">
							<v-icon>far fa-question-circle</v-icon>
						</v-btn>
					</div>
				</div>
			</v-container>
		</div>
		<v-container>
			<!-- <v-row >
				<v-col cols="12" class="py-0">
					<div class="page-search">
						<v-text-field
							placeholder="Поиск"
							filled
							rounded
							dense
						></v-text-field>
					</div>
				</v-col>
			</v-row> -->
			<v-row v-if="!loaded">
				<v-col class="p-0">
					<div >
						<v-skeleton-loader class="mx-0 p-0" type="article"></v-skeleton-loader>
					</div>
				</v-col>
			</v-row>
			<v-row v-if="loaded && appointments && getRepresentatives && getRepresentatives.length">
				<v-col cols="12">
					<v-select
						v-model="filters.idCustomers"
						:items="selectCustomers"
						label="Выбор пациентов"
						multiple
						chips
						hint="Выберите пациентов записи которых вы хотите просмотреть"
						persistent-hint
						deletable-chips
					></v-select>
				</v-col>
			</v-row>
			<v-row v-if="loaded">
				<v-col cols=12 v-if="loaded == true && !filteredAppointments.length">
					<v-alert prominent text>
						Записи в истории отсутствуют
					</v-alert>
				</v-col>
			</v-row>
			<template v-if="filteredAppointments">
				<v-row>
					<v-col class="pt-0" cols="12"  :key="appointment.id" v-for="appointment in filteredAppointments">
						<v-card outlined>
							<v-list-item three-line>
								<v-list-item-content class="pb-0">
									<div class="d-flex justify-space-between">
										<div>
											<div class="overline">
												<v-chip x-small v-if="appointment.specialName">
													{{ appointment.specialName }}
												</v-chip>
											</div>
											<p class=" mb-1">
												<v-icon class="mr-1" small style="margin-top: -2px">far fa-calendar-alt</v-icon>{{ appointment.additionalAppointment ? $moment(appointment.dateFrom).format("LL, dddd") : $moment(appointment.dateFrom).format("LL, dddd, LT") }}
											</p>
											<small style="font-size: 0.9em" v-if="appointment.customerSurname || appointment.customerName || appointment.customerMiddleName">
												<v-icon class="mr-1" small style="margin-top: -2px">fas fa-user</v-icon>
												<b class="grey--text text--darken-3">Пациент: </b>
												{{appointment.customerSurname}} {{appointment.customerName}} {{appointment.customerMiddleName}}
											</small><br>
											<small style="font-size: 0.9em" v-if="appointment.surname || appointment.name || appointment.middleName">
												<v-icon class="mr-1" small style="margin-top: -2px">fas fa-user-md</v-icon>
												<b class="grey--text text--darken-3">Специалист: </b>
												{{ appointment.surname }} {{ appointment.name }} {{ appointment.middleName }}
											</small>
										</div>
									</div>
								</v-list-item-content>
							</v-list-item>
							<v-card-actions class="pl-3 pb-3" >
								<v-btn class="text-decoration-none"
									:disabled="!appointment.idExamination"
									small
									text
									link
									:to="'/view-appointment?idExamination=' + appointment.idExamination"
									color="primary"
									:dark="!!(appointment.idExamination)"
								>
									Просмотреть
								</v-btn>
								<v-btn small rounded dark color="primary" v-if="appointment.reviewToken && appointment.reviewTokenId" @click="openReviewTab(appointment.reviewToken, appointment.reviewTokenId)">
									Оценить
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</template>
		</v-container>
	</div>
</template>

<!--
<script>

import { AppointmentsApiService, HistoryApiService } from '@/services/api'
import {mapGetters} from "vuex";

/*export default {
		name: 'Appointments',
		data: function() {
			return {
				appointments: [],
				loaded: false,
				title: 'Мои записи',
				filters: {
					idCustomers: [],
				}
			}
		},
		async mounted() {
			await this.getAppointments();
			this.filters.idCustomers = [this.getUser.id];
		},
		methods: {
			async getAppointments() {
				try {
					let appointments = await AppointmentsApiService.getAppointments()
					this.appointments = appointments
				} catch (err) {
					this.appointments = null
				} finally {
					this.loaded = true
				}
			},
			async getCoupons() {
				if (this.appointments.length != 0) {
					this.appointments.map(a => {
					HistoryApiService.getCoupons(a.id)
						.then(resp => {
							a.coupons = resp
						}).catch((a.coupons = []))
					})
				}
			}
		},
		computed: {
			...mapGetters([
				'getUser',
				'getRepresentatives'
			]),
			
			filteredAppointments() {
				return this.appointments.filter(val => this.filters.idCustomers.indexOf(val.id) !== -1 );
			}
		}
}*/

</script>
-->





<script>


import { HistoryApiService } from '@/services/api'
import router from '@/router';
import {mapGetters} from "vuex";

export default {
	name: 'History',

	data: function() {
		return {
			appointments: [],
			loaded: false,
			filterDoctor: '',
			filterSpecial: '',
			title: 'История посещений',
			filters: {
				idCustomers: [],
			}
		}
	},
	
	async mounted() {
		try {
			let history = await HistoryApiService.getHistory()
			this.appointments = history
		} catch (err) {
			this.appointments = null
		} finally {
			this.loaded = true
		}
		this.filters.idCustomers = [this.getUser.id]
	},
	
	created: function() {
		// Событие отправки сообщения
		this.$eventBus.$emit('setTitle', this.title);
	},
	
	methods: {
		toBack() {
				this.$router.push('/')
		},
		
		openReviewTab(token, id) {
			console.log(token, id);
			router.push(`/get-review?idReview=${id}&token=${token}&from=history`);
		},
	},
	
	computed: {
		...mapGetters([
			'getUser',
			'getRepresentatives'
		]),
		
		filteredAppointments() {
			return this.appointments.filter( val => this.filters.idCustomers.indexOf(val.customerId) !== -1 );
		},
		
		selectCustomers() {
			return [...this.getRepresentatives, this.getUser].map( val => ({
				text: `${val.surname ?? ''} ${val.name ?? ''} ${val.middleName ?? ''}`.trim(),
				value: val.id,
				disabled: false
			}) ).sort( (f, s) => f.value - s.value);
		}
	}
}
</script>


<style scoped>

.main-container{
		height: calc(100vh - 300px);
}

@media screen and  (max-width: 1200px){

		.main-container{
				height: calc(100vh - 220px);
		}

}

</style>