<template>
  <div>
    <div class="header-mobile">
      <v-container>
        <div class="top-toolbar">
          <div class="navigate-btn" >
            <v-btn icon @click="toBack">
              <v-icon>fas fa-angle-left</v-icon>
            </v-btn>
          </div>
          <div class="page-title">
            {{title}}
          </div>
          <div class="second-btn"></div>
        </div>
      </v-container>
    </div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card outlined >
            <v-card-text>
              <v-skeleton-loader :loading="loading" type="article" class="px-0">             
                <span class="black--text" v-html="examinationHtml"></span>                     
              </v-skeleton-loader>
            </v-card-text>
          </v-card>       
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  data() {
    return {
      title: 'Просмотр записи',
      examinationHtml: '',
      loading: true,
    }
  },
  computed: {
      
  },
  methods: {
    toBack: function(){
      this.$router.go(-1)
    },
    getExaminationHtml(){
      let idExamination = this.$route.query.idExamination;
      this.$http.get(window.config.apiUrl  + '/examinations?idExamination=' + idExamination + '&idCustomer=' + this.$store.getters.activeUser)
        .then((response) => {
          // Если нет ошибок
          this.examinationHtml = response.data;
          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        }); 
    },
    downloadFile(fid){
      this.$http.post(window.config.apiUrl  + '/examination/get-examination-file', {
          fid: fid,
          idCustomer: this.$store.getters.activeUser,
          idClient: this.$store.getters.getUser.idClient,
          token: this.$store.getters.token,
      }).then((response) => {
        console.log(response)
          if (response.data.url){
            console.log(response.data)
            //window.location = response.data.url;
            document.querySelector('.link-download-file[data-fid="' + fid + '"]').setAttribute('href', response.data.url);
            document.querySelector('.link-download-file[data-fid="' + fid + '"]').setAttribute('download', '');
            document.querySelector('.link-download-file[data-fid="' + fid + '"]').setAttribute('target', '_blank');
            document.querySelector('.link-download-file[data-fid="' + fid + '"]').click();
          }
        })
        .catch((error) => {
            console.log(error);
        });
    }
  },
  updated() {
      if (document.querySelectorAll('.link-download-file').length){
        document.querySelector('.link-download-file').addEventListener('click', (event) => { 
          let fid = event.target.getAttribute('data-fid');
          if (event.target.getAttribute('href') == '#'){
            this.downloadFile(fid);
            event.preventDefault();
            event.stopPropagation();
            return false;
          }
        })
        
      }
    
  },
  created: function() {
    // Событие отправки сообщения
    this.$eventBus.$emit('setTitle', this.title);
  },
  async mounted() {
    this.getExaminationHtml();
  }
}

</script>

<style scoped>

#iframe iframe{
  height: calc(100vh - 240px);
  width: 100%;
}

</style>