// Хранилище теле-медицины

export default {
  chat: {
      couponServices: [], // Услуги чата
      currentCouponService: 0, // Текущая выбранная услуга в чате
      callId: null, // Полученный свой номер peerJS
      callStatus: 'wait', // Статус видео-звонка
      noRulesAudio: true, // Права браузера на выполнение звука
      mutedMode: false, // Режим отключенного микрофона
      config: {
        mediaConfig: {
          video: {
            width: 320,  
            height: 240,
            frameRate: { 
              ideal: 10, 
              max: 15 
            },
            facingMode: 'user'
          },
          audio: true
        },
        iceServers: {
          url: 'turn:45.84.227.253:3478?transport=tcp', 
          credential: 'DhZe8Mm6', 
          username: 'health' 
        }
      }
    }
}