<template>
	<div class="message-file">
		<div class="" v-if="this.messageFile.type == 'png' || this.messageFile.type == 'jpg' || this.messageFile.type == 'jpeg'">
			<a :href="getFileMessageUrl(this.messageFile.guid, 'original')" target="_blank">
				<v-img contain :lazy-src="this.messageFile.preview" :src="getFileMessageUrl(this.messageFile.guid)" class="rounded-sm message-v-image"></v-img>
			</a>
		</div>
		<div v-else class="message-download-file">
			<a :href="getFileMessageUrl(this.messageFile.guid, 'original')" target="_blank">
				<i class="far fa-file-alt" aria-hidden="true"></i>
				<div class="file-info">{{this.messageFile.name}}.{{this.messageFile.type}}</div>
			</a>
		</div> 
	</div>
</template>

<script>

export default {
	name: 'MessageFile',
	props: [
		'messageFile',
	],
	methods: {
		getFileMessageUrl(guid, size = 'small'){
			return `${window.config.apiUrl}/chat/get-file-message?guid=${guid}&size=${size}&id=${this.$store.getters.getUser.id}&type=customer`
		},
	}
}
</script>

<style scoped>

.message-file{
	border-radius: 5px;
	margin-right: 10px;
	flex: 30%;
	margin-bottom: 8px;
	max-width: 185px;
	background: #ffffff1c;
}

.message-image-file{
	padding: 4px;
	height: 128px;
	min-height: 128px;
}

.message-v-image{
  max-height: 300px;
}

.message-download-file{
	font-size: 35px;
	text-align: center;
	padding: 25px 5px;
	color: #9ba6b2;
	min-width: 100px;
}

.message-download-file a{
	display: block;
	height: 100%;
	color: #9ba6b2;
	transition: .2s ease-in-out color;
}

.message-download-file a:hover{
  text-decoration: none;
  color: #868f99;
}

.file-info {
	font-size: 11px;
	color: #7e7e7e;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.wrap.incoming .message-download-file a *{
  color: #fff;
}

</style>