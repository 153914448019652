<template>
  <!-- <v-menu
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="calendar-btn"
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
      >{{calendarLabel}} <i class="fas fa-chevron-down"></i></div>
    </template>
    <v-date-picker
      v-model="date"
      @input="menu2 = false"
      @change="clickHandler"
      locale="ru-RU"
      :allowed-dates="getAllowedDates"
    ></v-date-picker>
  </v-menu> -->
  <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            class="calendar-btn"
            v-bind="attrs"
            v-on="on"
          >{{calendarLabel}} <i class="fas fa-chevron-down"></i></div>
        </template>
        <v-date-picker
          v-model="date"
          scrollable
          @input="menu2 = false"
          @change="clickHandler"
          locale="ru-RU"
          color="blue-grey lighten-4"
          header-color="primary"
          :allowed-dates="getAllowedDates"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modal = false"
          >
            Закрыть
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

  export default {
    data: () => ({
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      months: {
        0:"январь", 
        1:"февраль",
        2:"март",
        3:"апрель",
        4:"май",
        5:"июнь",
        6:"июль",
        7:"август",
        8:"сентябрь",
        9:"октябрь",
        10:"ноябрь",
        11:"декабрь",
      },
    }),
    props: {
      // установка текущей даты в сторе
      changeHandler: Function,
      // функция определения неактивныз дат в дейтпикере
      getAllowedDates: Function,
    },
    computed: {
      calendarLabel(){
        return this.months[(new Date(this.$store.getters.date)).getMonth()] + ' ' +
          (new Date(this.$store.getters.date)).getFullYear()
      }
    },
    methods: {
      // обработка выбора дыта
      clickHandler(e){
        // установка текущей даты в сторе
        this.changeHandler(new Date(e))
        this.modal = false
      },
    }
  }
</script>

<style scoped>
  .calendar-btn{
    font-size: 0.9rem;
    text-transform: uppercase;
    color: var(--primary-color);
  }

</style>