<template>
 <div>
        <div class="header-mobile">
          <v-container>
            <div class="top-toolbar">
                <div class="navigate-btn" >
                    <v-btn icon  @click="toBack" >
                        <v-icon>fas fa-angle-left</v-icon>
                    </v-btn>
                </div>
                <div class="page-title">
                    {{title}}
                </div>
                <div class="second-btn">
                    <v-btn icon >
                        <v-icon>far fa-question-circle</v-icon>
                    </v-btn>
                </div>
            </div>
            </v-container>
        </div>
            <div class="container py-0">
                <div class="row">
                    <div class="col-md-12 py-1 pb-4">
                        <div class="service-type-section" >
                            <div class="card py-2 mb-2">
                                <div class="card-body py-2">
                                    <v-row>
                                        <v-col cols=12 >
                                            <div id="result"></div>
                                        </v-col>
                                        <v-col cols=12 >
                                            <video id="localVideo" width="300" muted autoplay="true" playsinline controls></video>
                                        </v-col>
                                        <v-col cols=12 >
                                            <video id="remoteVideo" width="300" playsinline autoplay="true" controls></video>
                                        </v-col>
                                        <v-col cols=12 >
                                            <v-text-field
                                                label="Remote id"
                                                rounded
                                                outlined
                                                id="dest"
                                                dense
                                                autocomplete="off"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols=12 >
                                            <v-btn 
                                                rounded
                                                color="primary"
                                                block
                                                dark
                                                id="conn"
                                            >
                                            Connect
                                            </v-btn>
                                        </v-col>
                                         <v-col cols=12 >
                                            <v-btn 
                                                rounded
                                                color="primary"
                                                block
                                                dark
                                                id="call"
                                            >
                                            Call
                                            </v-btn>
                                        </v-col>

                                    </v-row>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
    </div>

</template>

<script>
import { mapGetters } from 'vuex';
import Peer from 'peerjs';



export default {
    data() {
        return {
            title: 'Peer test',
            dialog: false,
        }
    },
    computed: {
        ...mapGetters([
            'STATIC'
        ])
    },
    methods: {
        toBack() {
            this.$router.push('/')
        }
    },
    mounted: function() {
        // Событие отправки сообщения
    
        this.$eventBus.$emit('setTitle', this.title);






var conn = null;
var call = null;

var peer = new Peer({ 
    host: 'peer.health-ks.ru',
    secure: true,
    port: 9000,
    path: '/peerjs',
    debug: 3,
    config: {
        'iceServers': [
            { 
                url: 'turn:45.84.227.253:3478?transport=tcp', 
                credential: 'DhZe8Mm6', 
                username: 'health' 
            },
            
        ]
    }
});
      
peer.on('open', function(id) {
    alert('My id ' + id);
});
      
peer.on('connection', function (connection) {
    alert('connection');
    conn = connection;
    connection.on('open', function () {
        alert('Open');
    });
    connection.on('close', function () {
        alert('Close')
    });
    connection.on('error', function (error) {
        alert('Connection error')
        console.log(error)
    });
})
    
peer.on('error', function(err) { 
    alert('Peer error ' + err )
});
    
peer.on('close', function(err) { 
    alert('Close ' + err)
});
    
peer.on('disconnected', function(err) { 
    alert('Disconnected ' + err)
});
    
window.onerror = function(msg, url, lineNo, columnNo, error) {
    alert('Window error ' + msg + ' ' + error + ' ' + lineNo + ' ' + columnNo + '')
    return false;
}
    
function connect(id){
    alert('Try connect to ' + id )
    conn = peer.connect(id);
    conn.on('open', function () {
        alert('Open')
    });

    conn.on('data', function (data) {
        alert('Data ' + data ) 
    });
}

function error() {
                    console.log('Camera permission is not turned on');
                }
                function success() {
                    if (!status.hasPermission) error();
                    alert('Start call android')
                    navigator.mediaDevices.getUserMedia({
                        video: true
                    }).then(function(stream) {
                        alert('Get local stream')
                        document.getElementById("localVideo").srcObject = stream;
                    
                        call = peer.call(document.querySelector('#dest').value, stream);
                        call.on('stream', (remoteStream) => {
                            alert('Get remote stream')
                            document.getElementById("remoteVideo").srcObject = remoteStream;  
                        });
                    })
                    .catch(function(e){
                        alert('Failed to get local stream ' + e)
                        console.log(e)
                    });
                }

document.querySelector('#conn').addEventListener('click', function(){
    alert('click');
    connect(document.querySelector('#dest').value)
});


document.querySelector('#call').addEventListener('click', function(){
    alert('Try call')
    if (window.cordova){


    if (window.device){
            if (window.device.platform === 'iOS') {
                window.cordova.plugins.iosrtc.registerGlobals();
                alert('Start call ios')
                navigator.mediaDevices.getUserMedia({
                    video: true
                }).then(function(stream) {
                    alert('Get local stream')
                    document.getElementById("localVideo").srcObject = stream;
                
                    call = peer.call(document.querySelector('#dest').value, stream);
                    call.on('stream', (remoteStream) => {
                        alert('Get remote stream')
                        document.getElementById("remoteVideo").srcObject = remoteStream;  
                    });
                })
                .catch(function(e){
                    alert('Failed to get local stream ' + e)
                    console.log(e)
                });
            }
            if (window.device.platform === 'Android'){
                var permissions = window.cordova.plugins.permissions;
                var permList = [
                    permissions.CAMERA,
                    permissions.RECORD_AUDIO
                ];
                permissions.requestPermissions(permList, success, error);
                
            }
        }
    } else {
        alert('Start call browser')
        navigator.mediaDevices.getUserMedia({
            video: true
        }).then(function(stream) {
            alert('Get local stream')
            document.getElementById("localVideo").srcObject = stream;
        
            call = peer.call(document.querySelector('#dest').value, stream);
            call.on('stream', (remoteStream) => {
                alert('Get remote stream')
                document.getElementById("remoteVideo").srcObject = remoteStream;  
            });
        })
        .catch(function(e){
            alert('Failed to get local stream ' + e)
            console.log(e)
        });
    }

})





    }
}

</script>

<style scoped>

    .content-heading {
        font-size: 30px;
    }

    .site-contact {
        height: 50vh;
    }

    .main-container{
        height: calc(100vh - 240px);
    }

    

    @media screen and  (max-width: 1200px){


    }

</style>

<style >


</style>

    

