<template>
  <div v-if="timeByFilials">
    <div v-for="(times, filial, index) in timeByFilials" :key="index" class="filial-times" :class="[$mq === 'sm' ? 'min' : '']">
      <span style="margin: 5px;" v-if="filial !== 'all'">{{filial}}</span>
      <div class="schedule-container" :class="[$mq === 'sm' ? 'min' : '']">
        <div 
          class="schedule-item" 
          v-for="(time, index) in times" 
          :key="time" 
          @click="clickHandler(time, filial)"
          :class="[index >= 7 && hide && $mq === 'sm'? 'hide' : '']"
        >
          {{time}}
        </div>
        <div v-if="times.length > 8 && hide && $mq === 'sm'" class="schedule-item" @click="hide = false">
          <i class="fas fa-angle-double-down"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data(){
    return {
      hide: true,
    }
  },
  created(){
    
  },
  props: {
    freeIntervals: Object,
  },
  methods: {
    // обработка выбора времени приема
    clickHandler(time, filial){
      this.$emit('time-selected')
      this.$store.commit('setTime', time)
      localStorage.time = time
      if (!(filial === 'all')) {
        this.$store.commit('setAddress', filial)
        localStorage.address = filial
      }
    }
  },
  computed: {
    ...mapGetters(['date']),
    // формирование массива с разбиением на филлиалы
    timeByFilials(){
      if (!this.freeIntervals) return {}
      const times = {}
      for (let time of this.freeIntervals[this.date]) {
        if (!time.filialAddress) {
          times['all'] = []
        } else {
          times[time.filialAddress] = [] 
        } 
      }
      for (let time of this.freeIntervals[this.date]) {
        if (!time.filialAddress) {
          times['all'].push(time.timeFrom) 
        } else {
          times[time.filialAddress].push(time.timeFrom) 
        } 
      }
      return times
    }
  }
}
</script>

<style scoped>
  .schedule-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .schedule-container.min{
    justify-content: flex-start;
  }
  .schedule-item{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    min-width: 62px;
    text-align: center;
  }
  .schedule-item:hover{
    border: 1px solid var(--primary-color);
  }
  .filial-times{
    text-align: right;
    padding: 5px;
  }
  .filial-times.min{
    text-align: left;
  }
  .filial-times span{
    padding-right: 8px;
  }
  .hide{
    display: none;
  }
</style>