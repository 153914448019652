// Хранилище состояний работы с записями

export default {
    createAppointmentNew: {
        appointmentToken: null,
        time: '',
        date: '',
        address: '',
        age: null,
        width: 0,
        userId: null,
        idSpecial: '',
        service: null,
        users: {},
        q: '',
    },
}