<template>
    <v-footer app absolute color="white">
      <div class="main-footer">
          <div v-html="STATIC.appFooterContent">
            
          </div>
          <span>Разработано <a href="http://kiber-soft.net/" target="_blank">ООО «Кибер-Софт»</a></span>
      </div>
    </v-footer>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'Footer',
    computed: {
      ...mapGetters([
        'STATIC'
      ]),
    },
}
</script>

<style scoped>

span {
  margin: 0;
  display: block;
  text-align: center;

}

a {
  color: #3198bf;
  text-decoration: none;
}

a:hover {
  color: #3198bf;
  text-decoration: none;
}

.instagram {
  font-size: 20px;
  position: relative;
  top: 3px;
}

.main-footer{
    display: block;
    padding: 5px 0;
    font-size: 12px;
    text-align: center;
    margin: auto;
}

</style>
