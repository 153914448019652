<template>
    <div>
        <v-list-item 
            class="service-item" 
            @click="openCouponService" 
            :class="{'grey lighten-4' : this.currentCouponService == couponService.id}"
            :data-id="couponService.id"
        >
            <!-- <v-badge
                bordered
                bottom
                color="green accent-5"
                dot
                offset-x="25"
                offset-y="25"
            >
                <v-list-item-avatar class="ml-0">
                    <v-img position="top" :src="getUserPhoto(couponService.userId)"></v-img>
                </v-list-item-avatar>
            </v-badge> -->

                <v-list-item-avatar class="ml-0">
                    <v-img position="top" :src="getUserPhoto(couponService.idUser)"></v-img>
                </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title class="text-truncate">{{couponService.userName}}</v-list-item-title>
                <v-list-item-subtitle>{{couponService.serviceName}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="lastMessageTime || couponService.unreadCount">
                <v-list-item-action-text 
                    v-show="lastMessageTime"
                    v-text="lastMessageTime">
                </v-list-item-action-text>
                <v-badge
                    v-if="couponService.unreadCount"
                    offset-x="19"
                    offset-y="25"
                    color="primary"
                    :content="couponService.unreadCount"
                >
                </v-badge>
            </v-list-item-action>
        </v-list-item>
    </div>
</template>

<script>

import eventBus from '../../../eventBus';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
    name: 'ServiceItem',
    props: [
        'couponService',
        'index'
    ],
    methods: {
        // Открыть диалог услуги
        openCouponService() {
            eventBus.$emit('openCouponService', this.couponService.id);
            // Сбрасываем сообщение и вложение
            if (this.couponService.id != this.currentCouponService){
                eventBus.$emit('clearMessageInput');
            }

        },
        getUserPhoto(idUser){
            return window.config.apiUrl + '/file/get-user-photo?idUser=' + idUser
        },
    },
    computed: {
        ...mapGetters([
            'currentCouponService'
        ]),
        lastMessageTime: function(){
            if (this.couponService.messages.length){
                let message = this.couponService.messages[this.couponService.messages.length - 1];
                return moment(message.date, 'YYYY-MM-DD HH:mm').format('HH:mm');
            } else {
                return false; 
            }
        }
    }
}
</script>

<style scoped>

.wrap {
    padding: 3px 5px;
}

.item {
    background: #f7f7f8;
    padding: 12px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s all ease-in-out;
}

.wrap.active .item{
    background: #e9e6e6;
}

.item:hover,
.wrap.active .item:hover{
    background: #e0e0e0;
}


.wrap .item.inactive-service{
    border-left: 5px solid #edaeae;
    background: #eddbdb;
}

.top-section {
    display: flex;
    justify-content: space-between;
}

.customer-name {
    width: 255px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    font-weight: 700;
    padding-right: 10px;
}

.message-date {
    width: 50px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 10px;
    text-align: right;
    padding-top: 2px;
    opacity: 0.6;
}

.bottom-section {
    display: flex;
}

.service-name {
    width: 100%;
    font-size: 10px;
}

.message-count {
    width: 50px;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.chat-info-section{
    font-size: 10px;
    padding-top: 5px;
}

.service-item{
        border-bottom: 1px solid #f1f1f1;
}

</style>