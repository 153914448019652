<template>
    <v-app>
        <div class="form-signin-wrap">
            <div class="form-signin">
                <div class="container">
                    <div class="auth-img-wrap">
                        <img :src="STATIC.appLogoUrl" class="auth-img" alt="">
                    </div> 
                    <div class="form-group">
                        <div class="text-center">
                            <h3 class="font-weight-light">Регистрация</h3>
                        </div>
                        <div v-show="step == 'register-form'">
                            <h6 class="font-weight-light text-center mb-4">Регистрация нового пациента</h6>
                            <div class="form-group">
                                <v-form ref="form" v-model="registerForm.validRegisterForm" onsubmit="return false;">
                                    <v-text-field
                                        v-model="registerForm.surname"
                                        :rules="[rules.surname]"
                                        label="Фамилия"
                                        name="register-surname"
                                        required
                                        rounded
                                        type="text"
                                        maxlength="30"
                                        outlined
                                        autofocus
                                        dense
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="registerForm.name"
                                        :rules="[rules.name]"
                                        label="Имя"      
                                        name="register-name"
                                        rounded
                                        type="text"
                                        maxlength="30"
                                        required
                                        outlined
                                        dense
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="registerForm.middleName"
                                        label="Отчество"
                                        name="register-middlename"
                                        rounded
                                        :rules="[rules.middleName]"
                                        type="text"
                                        maxlength="30"
                                        outlined
                                        dense
                                    ></v-text-field>         
                                    <v-menu
                                        ref="menu"
                                        v-model="birthdayMenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        rounded="lg"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                              v-model="registerForm.birthday"
                                              :rules="[rules.birthday]"
                                              label="Дата рождения"
                                              name="register-birthday"
                                              v-mask="'####-##-##'"
                                              rounded
                                              v-bind="attrs"
                                              v-on="on"
                                              outlined
                                              required
                                              dense
                                              readonly
                                          ></v-text-field>
                                        </template>
                                        <v-card class="pa-3" rounded="lg">
                                          <div>
                                            <v-text-field
                                              outlined
                                              rounded
                                              dense
                                              label="Год"
                                              type="number"
                                              v-model="birthdayYear"
                                              :rules="[
                                                val => (val && +val > 1900) ? true : 'Введите корректный год. (4 цифры года рождения)',
                                                val => (val && +val === Math.floor(+val) ? true : 'Введите корректный год. (4 цифры года рождения)'),
                                                val => (val && val.indexOf('.') !== -1) ? 'Введите корректный год. (4 цифры года рождения)' : true,
                                              ]"
                                              v-mask="'####'"
                                            >
                                            </v-text-field>
                                          </div>
                                          <div>
                                            <v-text-field
                                              outlined
                                              rounded
                                              dense
                                              label="Месяц"
                                              type="number"
                                              v-model="birthdayMonth"
                                              :rules="[
                                                val => (val && +val >= 1 && +val <= 12) ? true : 'Введите корректный номер месяца. (1 или 2 цифры номера месяца)',
                                                val => (val && +val === Math.floor(+val) ? true : 'Введите корректный номер месяца. (1 или 2 цифры номера месяца)'),
                                                val => (val && val.indexOf('.') !== -1) ? 'Введите корректный номер месяца. (1 или 2 цифры номера месяца)' : true,
                                              ]"
                                              v-mask="'##'"
                                            >
                                            </v-text-field>
                                          </div>
                                          <div>
                                            <v-text-field
                                              outlined
                                              rounded
                                              dense
                                              label="День"
                                              type="number"
                                              v-model="birthdayDay"
                                              :rules="[
                                                val => (val && +val >= 1 && +val <= 31) ? true : 'Введите корректный день. (1 или 2 цифры день)',
                                                val => (val && +val === Math.floor(+val) ? true : 'Введите корректный день. (1 или 2 цифры день)'),
                                                val => (val && val.indexOf('.') !== -1) ? 'Введите корректный день. (1 или 2 цифры день)' : true,
                                              ]"
                                              v-mask="'##'"
                                            >
                                            </v-text-field>
                                          </div>
                                        </v-card>
<!--                                        <v-date-picker
                                            ref="picker"
                                            v-model="registerForm.birthday"
                                            :max="new Date().toISOString().substr(0, 10)"
                                            min="1900-01-01"
                                            color="primary"
                                            @change="save"
                                            flat
                                            reactive
                                            locale="ru-RU"
                                            no-title
                                            style="width: 100%"
                                        ></v-date-picker>-->
                                    </v-menu>
                                    <v-text-field
                                        v-model="registerForm.phone"
                                        :counter="18"
                                        :rules="[rules.phone]"
                                        label="Телефон"
                                        name="register-phone"
                                        rounded
                                        type="tel"
                                        maxlength="20"
                                        required
                                        outlined
                                        dense
                                        v-mask="'+7 (###) ###-##-##'"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="registerForm.email"
                                        label="E-mail"
                                        name="register-email"
                                        :rules="[rules.email]"
                                        rounded
                                        type="email"
                                        required
                                        outlined
                                        dense
                                    ></v-text-field>
                                    <v-select
                                        v-model="registerForm.gender"
                                        rounded
                                        :rules="[rules.gender]"
                                        outlined
                                        :items="genderList"
                                        label="Пол"
                                        dense
                                    ></v-select>
                                    <v-checkbox
                                      class="mt-0 mb-3"
                                      :rules="[
                                        val => val ? true : 'Необходимо принять условия соглашения'
                                      ]"
                                      v-if="STATIC.appPrivacyUrl && STATIC.appAgreementUrl"
                                    >
                                      <template v-slot:label>
                                        <span class="text-caption mt-2">
                                          Я принимаю условия&nbsp;<a :href="STATIC.appPrivacyUrl" target="_blank">Пользовательского соглашения</a>&nbsp;и&nbsp;<a
                                          :href="STATIC.appAgreementUrl" target="_blank">Политики конфиденциальности</a>
                                        </span>
                                      </template>
                                      
                                    </v-checkbox>
                                    <div class="d-flex align-center justify-space-between">
                                        <v-btn 
                                            :loading="loading" 
                                            :dark="registerForm.validRegisterForm" 
                                            :disabled="!registerForm.validRegisterForm" 
                                            rounded
                                            color="primary"
                                            block
                                            @click="checkRegisterForm"
                                        >Далее</v-btn>
                                    </div>
                            </v-form>
                            </div>
                        </div>
                        <div v-show="step == 'code-input'">
                            <h6 class="font-weight-light text-center mb-4">Мы позвоним вам на номер <br><b>{{this.registerForm.phone}}</b>, чтобы сообщить вам четырехзначный код подтверждения</h6>
                            <div class="form-group">
                                <v-form ref="form" v-model="codeForm.validCodeForm" onsubmit="return false;">
                                    <v-text-field
                                        v-model="codeForm.code"
                                        :rules="[rules.code]"
                                        autocomplete="off"
                                        autofocus
                                        :disabled="codeForm.timeOut == 0" 
                                        label="Код подтверждения"
                                        required
                                        rounded
                                        maxlength="4"
                                        :counter="4"
                                        v-mask="'####'"
                                        outlined
                                        dense
                                    ></v-text-field>
                                    <div class="d-flex align-center justify-space-between">
                                        <v-btn 
                                            :loading="loading" 
                                            :dark="codeForm.validCodeForm && (codeForm.timeOut > 0)" 
                                            :disabled="!codeForm.validCodeForm || !codeForm.timeOut" 
                                            rounded
                                            color="primary"
                                            block
                                            @click="checkCode"
                                        >Далее</v-btn>
                                    </div>
                                    <div class="mt-2 text-center" v-if="codeForm.timeOut >= 0">
                                        <small v-if="codeForm.timeOut > 0">Код действителен в течение {{codeForm.timeOut}} секунд</small>
                                        <v-btn 
                                            v-if="codeForm.timeOut == 0  && !loading"
                                            small 
                                            :dark="codeForm.repeatTimeOut == 0"
                                            rounded
                                            color="primary"
                                            block
                                            link
                                            :disabled="codeForm.repeatTimeOut > 0"
                                            @click="checkRegisterForm"
                                        >
                                        Выслать повторно код {{codeForm.repeatTimeOut ? '(' + codeForm.repeatTimeOut + ')' : ''}}
                                        </v-btn>
                                    </div>
                                </v-form>
                            </div>
                        </div>
                        <div v-show="step == 'password-input'">
                            <h6 class="font-weight-light text-center mb-4">Придумайте пароль для входа</h6>
                            <v-form ref="form" v-model="passwordForm.validPasswordForm" onsubmit="return false;">
                                <v-text-field
                                    v-model="passwordForm.password"
                                    :rules="[rules.password]"
                                    label="Пароль"
                                    autofocus
                                    required
                                    rounded
                                    outlined
                                    dense
                                    autocomplete="off"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="showPassword = !showPassword"
                                    :type="showPassword ? 'text' : 'password'"
                                ></v-text-field>
                                <v-text-field
                                    v-model="passwordForm.passwordRepeat"
                                    :rules="[rules.passwordRepeat]"
                                    label="Повторите пароль"
                                    required
                                    rounded
                                    outlined
                                    dense
                                    autocomplete="off"
                                    :append-icon="showPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="showPasswordRepeat = !showPasswordRepeat"
                                    :type="showPasswordRepeat ? 'text' : 'password'"
                                ></v-text-field>
                                <div class="d-flex align-center justify-space-between">
                                    <v-btn 
                                        :loading="loading" 
                                        :dark="passwordForm.validPasswordForm" 
                                        :disabled="!passwordForm.validPasswordForm" 
                                        rounded
                                        color="primary"
                                        block
                                        @click="checkPasswords"
                                    >Далее</v-btn>
                                </div>
                            </v-form>
                        </div>
                        <div v-show="step == 'agreed-documents'">
                            <h6 class="font-weight-light text-center mb-4">Примите следующие документы</h6>
                            <div class="form-group">
                                <small>
                                    <v-icon small class="mr-2">fas fa-check</v-icon> <a target="_blank" :href="STATIC.appPrivacyUrl">Согласие на обработку персональных данных</a><br>
                                    <v-icon small class="mr-2">fas fa-check</v-icon> <a target="_blank" :href="STATIC.appAgreementUrl">Публичный договор-оферта</a><br>
<!--                                    <v-icon small class="mr-2">fas fa-check</v-icon> <router-link to="/agreement">Информированное добровольное согласие на медицинские вмешательства</router-link><br>
                                    <v-icon small class="mr-2">fas fa-check</v-icon> <router-link to="/agreement">Уведомления о последствиях несоблюдения рекомендаций медицинской организации</router-link><br>-->
                                </small>
                            </div>
                            <div class="d-flex align-center justify-space-between">
                                <v-btn 
                                    :loading="loading" 
                                    dark
                                    rounded
                                    color="primary"
                                    block
                                    @click="agreedDocuments"
                                >Принять</v-btn>
                            </div>
                        </div>
                    </div>
                    <div class="form-group text-center text-muted">
                        <small><router-link to="/login">Вернутся назад</router-link></small>
                    </div>
                    <div class="form-group text-center text-muted">
                        <small>{{year}} © Киберсофт.Здоровье</small>
                    </div>       
                </div>
            </div>
        </div>
        <v-dialog v-model="registerForm.errorDialog" max-width="400">
            <v-card>
                <v-card-title>Регистрация</v-card-title>
                <v-card-text>
                    <p>Не удалось выполнить регистрацию. {{this.registerForm.errorText}}</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text rounded color="primary" dark @click="registerForm.errorDialog = false">Закрыть</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="codeForm.errorDialog" max-width="400">
            <v-card>
                <v-card-title>Регистрация</v-card-title>
                <v-card-text>
                    <p>{{this.codeForm.errorText}}</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text rounded color="primary" dark @click="codeForm.errorDialog = false">Закрыть</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="passwordForm.errorDialog" max-width="400">
            <v-card>
                <v-card-title>Регистрация</v-card-title>
                <v-card-text>
                    <p>Не удалось установить пароль. {{this.passwordForm.errorText}}</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text rounded color="primary" dark @click="passwordForm.errorDialog = false">Закрыть</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'Register',
    components: {},
    data: function() {
        return {
            step: 'register-form',
            newCustomerId: 0,
            registerToken: '',
            changePasswordToken: '',
            agreedDocumentsToken: '',
            codeValidate: false,
            repeatCodeTimeOut: 0,
            codeTimeOut: 0,
            registerForm: {
                surname: '',
                name: '',
                middleName: '',
                phone: '',
                birthday: null,
                validRegisterForm: false,
                errorText: '',
                errorDialog: false
            },
            passwordForm: {
                password: '',
                passwordRepeat: '',
                validPasswordForm: false,
                errorText: '',
                errorDialog: false
            },
            agreedForm: {
                errorText: '',
                errorDialog: false,
            },
            codeForm: {
                code: '',
                validCodeForm: false,
                errorText: '',
                errorDialog: false,
                timeOut: false,
                repeatTimeOut: false
            },
            birthdayDay: null,
            birthdayMonth: null,
            birthdayYear: null,
            showPassword: false,
            showPasswordRepeat: false,
            birthdayMenu: false,
            authError: false,
            loading: false,
            year: new Date().getFullYear(),
            genderList: [
                'Мужской',
                'Женский'
            ],
            rules: {
                surname: value => {
                    if (!value){
                        return 'Введите фамилию'
                    }
                    if (value.length > 30) {
                        return 'Фамилия не должна содержать более 30 символов';
                    }
                    return true;
                },
                name: value => {
                    if (!value){
                        return 'Введите имя'
                    }
                    if (value.length > 30) {
                        return 'Имя не должно содержать более 30 символов';
                    }
                    return true;
                },
                middleName: value => {
                    if (value.length > 30) {
                        return 'Отчество не должно содержать более 30 символов';
                    }
                    return true;
                },
                birthday: value => {
                    if (!value){
                        return 'Введите дату рождения'
                    }
                    console.log(value);
                    if (value){
                        let years = this.$moment().diff(this.$moment(value, 'YYYY-MM-DD'), 'years');
                        if (!this.$moment(value, 'YYYY-MM-DD').isValid()){
                            return 'Неверный формат даты рождения'
                        }
                        if (years < 18){
                            return 'Регистрация возможна только для совершеннолетних пациентов'
                        } 
                        return true;
                    }
                },
                email: value => {
                    if (!value){
                        return 'Введите e-mail'
                    }
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Неверный e-mail.'
                },
                gender: value => !!value || 'Выберите пол',
                phone: value => {
                    if (!value){
                        return 'Введите телефон'
                    }
                    if (value && value.length < 18) {
                        return 'Телефон должен быть не меньше 10 цифр';
                    }
                    return true;
                },
                code: value => {
                    if (!value) {
                        return 'Введите код подтверждения';
                    }
                    return true;
                },
                password: value => {
                    if (!value) {
                        return 'Введите пароль';
                    }
                    if (value.length < 8 || value.length > 20){
                        return 'Длинна пароля должна быть от 8 до 20 символов';
                    }
                    if (value.search(/[a-z]/i) < 0) {
                        return  'Пароль должен содержать хотя бы одну букву';
                    }
                    if (value.search(/[0-9]/) < 0) {
                        return 'Пароль должен содержать хотя бы одну цифру'; 
                    }
                    if (value.search(/[а-яА-ЯёЁ]/) >= 0) {
                        return 'Пароль должен содержать цифры и символы латинского алфавита'; 
                    }
                    return true;
                },
                passwordRepeat: value => {
                    if (!value) {
                        return 'Повторите пароль';
                    }
                    if (value != this.passwordForm.password) {
                        return 'Пароли не совпадают';
                    }
                    return true;
                },
            },
        }
    },
    watch: {
        birthdayMenu (val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
      
        birthdayDay(val) {
          if (!this.birthdayYear || !this.birthdayMonth) {
            return;
          }
          const month = (this.birthdayMonth.toString().length === 1) ? `0${this.birthdayMonth}` : this.birthdayMonth;
          const day = (val.toString().length === 1) ? `0${val}` : val;
          this.registerForm.birthday = `${this.birthdayYear}-${month}-${day}`;
        },
        
        birthdayMonth(val) {
          if (!this.birthdayYear) {
            return;
          }
          const month = (val.toString().length === 1) ? `0${val}` : val;
          this.registerForm.birthday = `${this.birthdayYear}-${month}`;
          if (this.birthdayDay) {
            const day = (this.birthdayDay.toString().length === 1) ? `0${this.birthdayDay}` : this.birthdayDay;
            this.registerForm.birthday += `-${day}`;
          }
        },
  
        birthdayYear(val) {
          this.registerForm.birthday = `${val}`;
          if (this.birthdayMonth) {
            const month = (this.birthdayMonth.toString().length === 1) ? `0${this.birthdayMonth}` : this.birthdayMonth;
            this.registerForm.birthday += `-${month}`;
            if (this.birthdayDay) {
              const day = (this.birthdayDay.toString().length === 1) ? `0${this.birthdayDay}` : this.birthdayDay;
              this.registerForm.birthday += `-${day}`;
            }
          }
        },
    },
    mounted () {
        setInterval(() =>{
            // Если время кода идет
            if (this.codeForm.timeOut > 0){
                --this.codeForm.timeOut;
            } 
            // Если время кода закончилось
            // if (this.codeForm.repeatTimeOut == 0){
            //     this.codeForm.repeatTimeOut = false;
            //     this.codeForm.timeOut = false;
            // }
            // Если время повтора кода идет
            if (this.codeForm.repeatTimeOut > 0){
                --this.codeForm.repeatTimeOut;
            }
        }, 1000)
    },
    methods: {
        ...mapActions([
            'login'
        ]),
        checkRegisterForm() {
            this.loading = true;
            this.codeForm.code = '';
            let data = new FormData();
            data.append('Register[surname]', this.registerForm.surname);
            data.append('Register[name]', this.registerForm.name);
            data.append('Register[middleName]', this.registerForm.middleName);
            data.append('Register[birthday]', this.registerForm.birthday);
            data.append('Register[email]', this.registerForm.email);
            data.append('Register[phone]', this.registerForm.phone);
            data.append('Register[gender]', this.registerForm.gender);
            fetch(
                window.config.apiUrl + '/register/get-register-code', {
                method: 'POST',
                body: data
            }).then(response => response.json())
            .then((response) => {
                console.log(response);
                this.loading = false;
                if (response.status == 'success'){
                    this.step = 'code-input';
                    this.registerToken = response.token;
                    this.codeForm.timeOut = response.codeTimeOut;
                    this.codeTimeOut = response.codeTimeOut
                    this.repeatCodeTimeOut = response.repeatCodeTimeOut
                    this.codeForm.repeatTimeOut = this.repeatCodeTimeOut;
                } else {
                    this.registerForm.errorDialog = true;
                    this.registerForm.errorText = response.errorText;
                }
            });  
        },
        checkCode() {
            this.loading = true;
            let data = new FormData();
            data.append('code', this.codeForm.code);
            data.append('token', this.registerToken);
            data.append('Register[surname]', this.registerForm.surname);
            data.append('Register[name]', this.registerForm.name);
            data.append('Register[middleName]', this.registerForm.middleName);
            data.append('Register[birthday]', this.registerForm.birthday);
            data.append('Register[email]', this.registerForm.email);
            data.append('Register[phone]', this.registerForm.phone);
            data.append('Register[gender]', this.registerForm.gender);
  
            const customerSource = localStorage.getItem('customerSource');
            data.append('source', customerSource);
            fetch(
                window.config.apiUrl + '/register/check-code', {
                method: 'POST',
                body: data
            }).then(response => response.json())
            .then((response) => {
                console.log(response);
                this.loading = false;
                if (response.status == 'success'){
                    this.step = 'password-input';
                    this.newCustomerId = response.customerId;
                    this.changePasswordToken = response.changePasswordToken;
                } else {
                    this.codeForm.errorDialog = true;
                    this.codeForm.errorText = response.errorText;
                }
            });  
        },
        checkPasswords () {
            this.loading = true;
            let data = new FormData();
            data.append('password', this.passwordForm.password);
            data.append('token', this.changePasswordToken);
            data.append('idCustomer', this.newCustomerId);
            fetch(
                window.config.apiUrl + '/register/set-password', {
                method: 'POST',
                body: data
            }).then(response => response.json())
            .then((response) => {
                console.log(response);
                this.loading = false;
                if (response.status == 'success'){
                    this.step = 'agreed-documents';
                    this.agreedDocumentsToken = response.agreedDocumentsToken;
                } else {
                    this.passwordForm.errorDialog = true;
                    this.passwordForm.errorText = response.errorText;
                }
            }); 
        },
        agreedDocuments() {
            this.loading = true;
            let data = new FormData();
            data.append('idCustomer', this.newCustomerId);
            data.append('token', this.agreedDocumentsToken);
            fetch(
                window.config.apiUrl + '/register/agreed-documents', {
                method: 'POST',
                body: data
            }).then(response => response.json())
            .then((response) => {
                console.log(response);
                if (response.status == 'success'){
                    let authParams = {
                        login: this.registerForm.phone,
                        password: this.passwordForm.password
                    };
                    this.login(authParams)
                    .then(() => {
                        window.location = '/';
                    })
                    .catch(err => {
                        console.log(err)
                        this.loading = false;
                        this.agreedForm.errorDialog = true;
                        this.agreedForm.errorText = 'Не удалось авторизировать пользователя';
                    })
                } else {
                    this.loading = false;
                    this.agreedForm.errorDialog = true;
                    this.agreedForm.errorText = response.errorText;
                }
            }); 

            // Авторизуем и получаем токен
            // Перенаправляем на начальную страницу
        },
        save (date) {
            this.$refs.menu.save(date)
        },
 
    // Метод логина
    async signIn() {
    //   this.authError = false;
    //   this.$refs.form.validate()
    //   // Проверяем валидацию
    //   if (this.valid) {
    //     let authParams = {
    //       login: this.loginForm.login,
    //       password: this.loginForm.password
    //     };
    //     this.loading = true;
    //     this.login(authParams)
    //       .then(() => {
    //         this.loading = false;
    //         this.$router.push('/select-user')
    //       })
    //       .catch(err => {
    //         console.log(err)
    //         this.authError = true
    //         this.loading = false
    //       })
    //   }
    }
  },
  computed: {
    ...mapGetters([
        'LOGIN_PHONE',
        'STATIC'
    ])
  }
}
</script>

<style>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.form-signin {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    margin: auto;
}

.form-signin-wrap{
    display: flex;
    height: 100%;
}

.register-animate-enter-active {
  animation: coming .6s;
  animation-delay: .8s;
  opacity: 0;
}

.register-animate-leave-active {
  animation: going .6s;
}

.auth-img{
    margin: auto;
    height: 80px;
    object-fit: contain;
    margin-bottom: 10px;
    pointer-events: none;
}

@keyframes going {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-50px);
    opacity: 0;
  }
}

@keyframes coming {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


</style>
